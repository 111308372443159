<template>
    <div class="addNewItem">
        <loader v-if="!rendered">
        </loader>
        <div class="addNewItem__title" v-if="rendered"> 
            <span v-if="this.item === undefined" class="md-display-1">Nowy Przedmiot</span>
            <div v-if="this.item !== undefined && product.mainImage.url.length>0"> <img :src="product.mainImage.url"> </div>
            <span v-if="this.item !== undefined" class="md-display-1">Edycja Przedmiotu - {{product.name}}</span>
            <md-button v-if="this.item !== undefined" @click="goBackToList" class="md-accent"> Cofnij</md-button>
        </div>
        <div class="addNewItem__steps" v-if="rendered">
            <md-steppers md-alternative :md-active-step.sync="currentStep" :md-dynamic-height="true">
            <md-step id="first" md-label="Podstawowe" >
                <md-toolbar :md-elevation="1">
                    <span class="md-headline">Główne</span>
                </md-toolbar>
                <div class="addNewItem__columns">
                    <div class="addNewItem__columns__column">
                        <div class="addNewItem__options">
                            <div class="addNewItem__options__option">
                                <div class="addNewItem__options__option__doubleText">
                                    <span class="md-title">Nazwa przedmiotu</span>
                                    <span class="md-body-1">Maksymalnie 55-60 znaków pod wyszukiwarkę Google. Będzie to główna nazwa widoczna w aukcji przedmiotu.</span>
                                </div>
                                <md-field id="naming" :class="{'md-invalid': errors.nameError}">
                                    <label>Nazwa Przedmiotu</label>
                                    <md-input v-model="product.name" maxlength="25"></md-input>
                                    <span class="md-error">Brak nazwy przedmiotu.</span>
                                </md-field>                           
                            </div>
                            <div class="addNewItem__options__option">
                                <span class="md-title">Informacja w kafelku (Max. 3szt. - krótkie)</span>
                                <md-chips  v-model="product.snippet" :md-limit="3" :md-check-duplicated="true" md-placeholder="Dodaj specyfikację w kafelek.."></md-chips>                        
                            </div>
                            <div class="addNewItem__options__option">
                                <span class="md-title">Producent</span>
                                <md-field>
                                    <label for="manufacturers">Wybierz producenta</label>
                                    <md-select v-model="product.manufacturer">
                                        <md-option value="">Pole puste</md-option>
                                        <md-option value='Acer'>Acer</md-option>
                                        <md-option value='AOC'>AOC</md-option>
                                        <md-option value='Apple'>Apple</md-option>
                                        <md-option value='Asus'>Asus</md-option>
                                        <md-option value='Dell'>Dell</md-option>
                                        <md-option value='EIZO'>EIZO</md-option> 
                                        <md-option value='Fujitsu'>Fujitsu</md-option>
                                        <md-option value='HP'>HP</md-option>
                                        <md-option value='Iiama'>Iiama</md-option>
                                        <md-option value='Lenovo'>Lenovo</md-option>
                                        <md-option value='LG'>LG</md-option> 
                                        <md-option value='Microsoft'>Microsoft</md-option>
                                        <md-option value='MSI'>MSI</md-option>
                                        <md-option value='NEC'>NEC</md-option>
                                        <md-option value='Panasonic'>Panasonic</md-option>
                                        <md-option value='Philips'>Philips</md-option>
                                        <md-option value='Samsung'>Samsung</md-option>
                                        <md-option value='Sony'>Sony</md-option>
                                        <md-option value='Toshiba'>Toshiba</md-option>
                                        <md-option value='Inna marka'>Inna marka</md-option>                                                                                           
                                    </md-select>
                                </md-field>                  
                            </div>
                            <div class="addNewItem__options__option">
                                <div class="addNewItem__options__option__doubleText">
                                    <span class="md-title">Model produktu / Oznaczenie</span>
                                </div>
                                <md-field id="naming">
                                    <label>Oznaczenie producenta</label>
                                    <md-input v-model="product.model"></md-input>
                                </md-field>                           
                            </div>
                            <div class="addNewItem__options__option">
                                <div class="addNewItem__options__option__doubleText">
                                    <span class="md-title">EAN</span>
                                </div>
                                <md-field id="innerCode" >
                                    <label>Kod EAN</label>
                                    <md-input v-model="product.ean" maxlength="14"></md-input>
                                </md-field>                           
                            </div>
                            <div class="addNewItem__options__option">
                                <div class="addNewItem__options__option__doubleText">
                                    <span class="md-title">SKU</span>
                                </div>
                                <md-field id="innerCode" >
                                    <label>SKU</label>
                                    <md-input @keyup="checkSku()" v-model="product.sku"></md-input>
                                </md-field>                           
                            </div>
                            <div class="addNewItem__options__option">
                                <div class="addNewItem__options__option__doubleText">
                                    <span class="md-title">Kod wewnętrzny</span>
                                </div>
                                <md-field id="innerCode" >
                                    <label>Oznaczenie własne</label>
                                    <md-input v-model="product.innerCode" maxlength="10"></md-input>
                                </md-field>                           
                            </div>
                            <div class="addNewItem__options__option">
                                <div class="addNewItem__options__option__doubleText">
                                    <span class="md-title">Położenie</span>
                                </div>
                                <md-field id="innerCode" >
                                    <label>Regał / Półka</label>
                                    <md-input v-model="product.location"></md-input>
                                </md-field>                           
                            </div>
                            <div class="addNewItem__options__option">
                                <div class="addNewItem__options__option__doubleText">
                                    <span class="md-title">Czas wysyłki</span>
                                </div>
                                <md-field>
                                    <label>Czas wysyłki (H)</label>
                                    <md-input v-model="product.deliveryTime" type="number"></md-input>
                                    <span class="md-suffix">H</span>
                                </md-field>                           
                            </div>
                            <div class="addNewItem__options__option">
                                <span class="md-title">Kategoria</span>
                                <md-field>
                                    <label for="category">Kategoria Główna</label>
                                    <md-select v-model="product.category" @md-selected="changeCategory(product.category)" name="category" id="category">
                                        <md-option v-for="cat in availableCategories" :value="cat.name" :key="cat.name">{{cat.name}}</md-option>
                                    </md-select>
                                </md-field>                        
                            </div>
                            <div class="addNewItem__options__option">
                                <span class="md-title">Podkategoria</span>
                                <md-field>
                                    <label for="subcategory">Subkategoria</label>
                                    <md-select v-model="product.subcategory" name="subcategory" id="subcategory">
                                        <md-option value="">Nie dotyczy</md-option>
                                        <md-option v-for="subcat in pickedSubcategory" :value="subcat" :key="subcat">{{subcat}}</md-option>
                                    </md-select>
                                </md-field>                        
                            </div>
                            <div class="addNewItem__options__option">
                                <md-switch v-model="product.isUnique">Produkt Unikalny</md-switch>                          
                            </div>
                            <div class="addNewItem__options__option" v-if="product.isUnique === false">
                                <span class="md-title">Dostępna Ilość</span>
                                <md-field id="qnty" :class="{'md-invalid': errors.qtyError}">
                                    <label>Ilość</label>
                                    <md-input v-model="product.quantity" type="number"></md-input>
                                    <span class="md-suffix">Szt.</span>
                                    <span class="md-error">Ilość poniżej 1 szt.</span>
                                </md-field>                          
                            </div>
                            <div class="addNewItem__options__option">
                                <span class="md-title">Klasa Towaru</span>
                                <md-field>
                                <label for="movie">Klasa</label>
                                    <md-select v-model="product.qualityClass" @md-selected="changeItemCondition(product.qualityClass)" name="itemClass" id="itemClass">
                                        <md-option value="Nowy">Nowy</md-option>
                                        <md-option value="Powystawowy">Powystawowy</md-option>
                                        <md-option value="A">A</md-option>
                                        <md-option value="A-">A-</md-option>
                                        <md-option value="B">B</md-option>
                                        <md-option value="C">C</md-option>
                                    </md-select>
                                </md-field>                        
                            </div>
                            <div class="addNewItem__options__option">
                                <span id="pricee" class="md-title" v-if="!product.promotion">Cena Podstawowa</span>
                                <span id="pricee" class="md-title" v-if="product.promotion">Cena Promocyjna</span>
                                <md-field :class="{'md-invalid': errors.priceError}">
                                    <label>Cena</label>
                                    <md-input v-model="product.basePrice" type="number"></md-input>
                                    <span class="md-suffix">PLN</span>
                                    <span class="md-error">Cena musi być większa niż 0 PLN.</span>
                                </md-field>                       
                            </div>
                            <div class="addNewItem__options__option" v-if="product.promotion">
                                <span id="pricee" class="md-title">Cena przed promocją</span>
                                <md-field>
                                    <label>Cena</label>
                                    <md-input v-model="product.priceBeforePromotion" type="number"></md-input>
                                    <span class="md-suffix">PLN</span>
                                </md-field>                       
                            </div>
                            <div class="addNewItem__options__option">
                                <md-switch v-model="product.inpost.allowed" @change="product.inpost.allowed === true ? product.inpost.amountInOnePackage = 1 : product.inpost.amountInOnePackage = 0"></md-switch>  
                                <span id="pricee" class="md-title">Paczkomat InPost</span>       
                            </div>
                        </div>
                    </div>
                </div>
                <md-toolbar :md-elevation="1">
                    <span class="md-headline">Galeria Zdjęć</span>
                    <md-menu md-direction="bottom-start" v-if="item !== undefined" style="margin-left: auto;">
                    <md-button md-menu-trigger v-show="selectedImage.url.length>0">Zarządzaj wybranym</md-button>
                    
                        <md-menu-content>
                            <md-menu-item @click="makeItMainImage">Ustaw główne</md-menu-item>
                            <md-menu-item @click="deleteFromGallery">Usuń</md-menu-item>
                            <md-menu-item @click="deleteAllFromGallery">Usuń wszystko</md-menu-item>
                            <md-menu-item @click="moveImageLeft" v-if="selectedImage.indx>1">Przesuń obrazek w lewo</md-menu-item>
                            <md-menu-item @click="moveImageRight" v-if="selectedImage.indx !== allImages.length-1">Przesuń obrazek w prawo</md-menu-item>
                        </md-menu-content>
                    </md-menu>
                </md-toolbar>
                <div class="addNewItem__imageGallery" v-if="item !== undefined">
                        <div class="addNewItem__imageGallery__uploadNew">
                            <span class="md-title">Upload</span>
                            <span class="md-subheading">Dodaj kolejne zdjęcia do galerii.</span>
                            <div class="addNewItem__imageGallery__uploadNew__uploader">
                                <md-field style="width: 450px;">
                                    <label>Dodaj..</label>
                                    <md-file v-model="clearer" ref="editImageUploader" accept="image/*" multiple/>
                                </md-field>
                                <md-button class="md-dense md-raised md-primary" @click="uploadToAllImages" v-if="showEditModeUpload">Uploaduj</md-button>
                                <md-progress-spinner :md-diameter="30" :md-stroke="3" md-mode="indeterminate" v-if="!showEditModeUpload"></md-progress-spinner>
                            </div>
                            <span class="md-title">Kolekcja</span>
                            <span class="md-subheading">Zarządzaj załadowanymi zdjęciami.</span>       
                        </div>
                        <div class="addNewItem__imageGallery__galleryRows" v-for="y in Math.ceil(allImages.length / 6)" :key="y">
                            <div class="addNewItem__imageGallery__galleryRows__image" :class="{chosenAsMain: gallery.url === product.mainImage.url, selectedFromGallery: selectedImage === gallery}" v-for="(gallery,picNum) in allImages.slice((y - 1) * 6, y * 6)" :key="picNum">
                                <div><img :src="gallery.url"  @click="selectImageFromGallery(picNum,y)"></div>
                            </div>
                            
                            
                        </div>
                </div>
                <div class="addNewItem__columns" v-if="item === undefined">
                    <div class="addNewItem__columns__column">
                        <div class="addNewItem__options">
                            <div class="addNewItem__options__option" >
                                <div class="addNewItem__options__option__doubleText">
                                    <span class="md-title">Zdjęcie Podglądowe</span>
                                    <span class="md-body-1">Obrazek wyświetlany jako miniaturka oraz główne zdjęcie produktu.</span>  
                                </div>
                                <md-field>
                                    <label>Dodaj</label>
                                    <md-file ref="main" accept="image/*"/>
                                </md-field>                       
                            </div>
                            
                        </div>
                    </div>
                    <div class="addNewItem__columns__column">
                        <div class="addNewItem__options">
                            <div class="addNewItem__options__option" >
                                <div class="addNewItem__options__option__doubleText">
                                    <span class="md-title">Galeria Zdjęć</span>
                                    <span class="md-body-1">Obrazki pokazowe produktu.</span>
                                </div>
                                <md-field>
                                    <label>Dodaj</label>
                                    <md-file multiple ref="gallery"  accept="image/*"/>
                                </md-field>                             
                            </div>
                        </div>
                    </div>
                </div>
                <div class="imgError" v-show="errors.imageError">
                    <span class="caption" style=""> Nie wgrano zdjęcia podglądowego!</span>
                </div> 
                <md-toolbar :md-elevation="1">
                    <span class="md-headline">Optymalizacja</span>
                </md-toolbar>
                <div class="addNewItem__columns">
                    <div class="addNewItem__columns__column">
                        <div class="addNewItem__options">
                            <div class="addNewItem__options__option">
                                <div class="addNewItem__options__option__doubleText">
                                    <span class="md-title">Meta Tags</span>
                                    <span class="md-body-1">Słowa kluczowe produktu nieindeksowane przez Google.</span>
                                </div>
                                <md-chips class="md-primary" md-deletable v-model="product.keywords" md-placeholder="Dodaj keyword..."></md-chips>                            
                            </div>
                            <div class="addNewItem__options__option">
                                <div class="addNewItem__options__option__doubleText">
                                    <span class="md-title">Teksty Alternatywne</span>
                                    <span class="md-body-1">Ilość opisów powinna odpowiadać ilości zdjęć lub być większa.</span>
                                </div>
                                <md-chips class="md-primary" md-deletable v-model="product.imgAlt" md-placeholder="Dodaj keyword..."></md-chips>                            
                            </div>
                        </div>
                        
                    </div>
                    <div class="addNewItem__columns__column">
                        <div class="addNewItem__options">
                            <div class="addNewItem__options__option">
                                <div class="addNewItem__options__option__doubleText">
                                    <span class="md-title">Meta Description</span>
                                    <span class="md-body-1">Opis w wyszukiwarce Google.</span>
                                </div>
                                <div>
                                    <md-field>
                                        <label>Krótki opis 50-160 znaków.</label>
                                        <md-textarea v-model="product.metaDescription" md-autogrow maxlength="160"></md-textarea>
                                    </md-field> 
                                </div>                      
                            </div>
                        </div>
                    </div>
                </div>
            </md-step>
            <md-step id="second" md-label="Rozbudowa">
                <md-toolbar :md-elevation="1">
                    <span class="md-headline">Kreator Opcji Dopłatowych</span>
                </md-toolbar>
                <div class="addNewItem__columns">
                    <div class="addNewItem__columns__column">
                        <div class="addNewItem__options">
                            <div class="addNewItem__options__newAddon">
                                <md-button @click="toggleAddonLoadTab()">Wczytaj z istniejących <md-tooltip md-direction="bottom">Pozwala na wczytanie opcji rozbudowy z bazy danych.</md-tooltip></md-button>           
                            </div>
                        </div>
                    </div>
                </div>
                <div class="addNewItem__addons">
                    <div class="addNewItem__addons__addon" v-for="(addon,addonIndex) in preparedAddons" :key="addonIndex">
                        <div class="addNewItem__addons__addon__main">
                            <span class="md-title">{{addon.name}}</span> <img class="closeButton" @click="deleteAddon(addonIndex)" src="../assets/svg/fi-rr-cross-circle.svg">
                        </div>
                        <div class="addNewItem__addons__addon__columns__column">
                            <div class="addNewItem__addons__addon__columns__column__variant" v-for="(variant,variantIndex) in addon.options" :key="variantIndex"><span :class="{disabledOpt: variant.unavailableIn.includes(product.uniqueID)}">{{variant.name}} <span v-if="!addon.percantagePrice">- {{variant.price}} PLN</span><span v-if="addon.percantagePrice">({{variant.percantage}}% ceny) - {{product.basePrice*(variant.percantage/100)}}PLN</span></span>
                            <md-button class="md-accent" @click="removeFromAvailableOptions(variant,addon)" v-if="variant.availableIn.includes(product.uniqueID) === true && item !== undefined">Dezaktywuj</md-button> <md-button class="md-primary" v-if="variant.availableIn.includes(product.uniqueID) === false && item !== undefined" @click="addToAvailableOptions(variant,addon)">Aktywuj</md-button> </div>
                        </div>


                    </div>
                    <md-progress-bar v-show="addingVariantLoading" class="md-accent" md-mode="indeterminate"></md-progress-bar>
                </div>
            </md-step>
            <md-step id="third" md-label="Specyfikacja">
                <md-toolbar :md-elevation="1">
                    <span class="md-headline">Podzespoły & Ogólne</span>
                </md-toolbar>
                <div class="addNewItem__columns">
                    <div class="addNewItem__columns__column">
                        <div class="addNewItem__options">
                            <div class="addNewItem__options__option">
                                <span class="md-title">Kondycja</span>
                                <md-field>
                                    <label for="dvd">Wybierz opcję</label>
                                    <md-select v-model="product.specs.itemCondition.value" @md-selected="changeItemClass(product.specs.itemCondition.value)">
                                        <md-option value=''>Nie dotyczy</md-option>
                                        <md-option value='"A" poleasingowy, przetestowany'>"A" poleasingowy, przetestowany</md-option>
                                        <md-option value='"A-" poleasingowy, przetestowany'>"A-" poleasingowy, przetestowany</md-option>
                                        <md-option value='"B" poleasingowy, przetestowany'>"B" poleasingowy, przetestowany</md-option>
                                        <md-option value='"C" poleasingowy, przetestowany'>"C" poleasingowy, przetestowany</md-option>
                                        <md-option value='Powystawowy / Leżak magazynowy'>Powystawowy / Leżak magazynowy </md-option>
                                        <md-option value='Nowy'>Nowy</md-option>
                                    </md-select>
                                </md-field>                          
                            </div>
                            <div class="addNewItem__options__option">
                                <span class="md-title">Stan obudowy</span>
                                <md-field>
                                    <label>Stan obudowy</label>
                                    <md-input v-model="product.specs.casingCondition.value"></md-input>
                                </md-field>                           
                            </div>
                            <div class="addNewItem__options__option" v-if="product.category != 'Monitory' && product.category != 'Akcesoria' && product.category != 'Części laptopowe' && product.category != 'Części komputerowe' && product.category != 'Tablety' && product.category != 'Telefony'">
                                <span class="md-title">Seria procesora</span>
                                <md-field>
                                    <label for="manufacturers">Wybierz serie procesora</label>
                                    <md-select v-model="product.specs.processorType.value">
                                        <md-option value="">Pole puste</md-option>
                                        <md-option value="Intel Core i3">Intel Core i3</md-option>
                                        <md-option value="Intel Core i5">Intel Core i5</md-option>
                                        <md-option value="Intel Core i7">Intel Core i7</md-option>
                                        <md-option value="Intel Core i9">Intel Core i9</md-option>
                                        <md-option value="Intel Xeon">Intel Xeon</md-option>
                                        <md-option value="Intel Celeron">Intel Celeron</md-option>
                                        <md-option value="Intel Pentium">Intel Pentium</md-option>
                                        <md-option value="Intel Core 2 Duo">Intel Core 2 Duo</md-option>
                                        <md-option value="Intel Core 2 Quad">Intel Core 2 Quad</md-option>
                                        <md-option value="Inny Intel">Inny Intel</md-option>
                                        <md-option value="Inny AMD">Inny AMD</md-option>                                                                                             
                                    </md-select>
                                </md-field>                  
                            </div>
                            <div class="addNewItem__options__option" v-if="product.category != 'Monitory' && product.category != 'Akcesoria' && product.category != 'Części laptopowe' && product.category != 'Części kmputerowe' && product.category != 'Tablety' && product.category != 'Telefony'">
                                <span class="md-title">Model procesora</span>
                                <md-field>
                                    <label for="processor">Wybierz opcję</label>
                                    <md-select v-model="product.specs.processor.value" name="processor" id="processor">
                                        <md-option value="">Nie dotyczy</md-option>
                                        <md-option value="Brak">Brak</md-option>
                                        <md-optgroup label="Komputerowe" v-show="product.category !== 'Laptopy'">
                                            <md-option v-for="(cpu,cpuIndex) in attribs.ProcessorsList.computerOptions" :key="cpuIndex" :value="cpu">{{cpu}}</md-option>
                                        </md-optgroup>
                                        <md-optgroup label="Laptopowe" v-show="product.category === 'Laptopy'">
                                            <md-option v-for="(cpu,cpuIndex) in attribs.ProcessorsList.notebookOptions" :key="cpuIndex" :value="cpu">{{cpu}}</md-option>
                                        </md-optgroup>
                                    </md-select>
                                </md-field>                           
                            </div>
                            <div class="addNewItem__options__option" v-if="product.category != 'Monitory' && product.category != 'Akcesoria' && product.category != 'Części laptopowe' && product.category != 'Części komputerowe' && product.category != 'Tablety' && product.category != 'Telefony'">
                                <span class="md-title">Taktowanie procesora</span>
                                <md-field>
                                <label for="cpuSpeed">Wybierz opcję</label>
                                <md-select v-model="product.specs.cpuSpeed.value" name="cpuSpeed" id="cpuSpeed">
                                    <md-option value="">Nie dotyczy</md-option>
                                    <md-optgroup label="Komputerowe" v-show="product.category !== 'Laptopy'">
                                        <md-option v-for="(cpuSpeed,cpuSpeedIndex) in attribs.ProcClockingList.compOptions" :key="cpuSpeedIndex" :value="cpuSpeed">{{cpuSpeed}}</md-option>
                                    </md-optgroup>
                                    <md-optgroup label="Laptopowe" v-show="product.category === 'Laptopy'">
                                        <md-option v-for="(cpuSpeed,cpuSpeedIndex) in attribs.ProcClockingList.notebookOptions" :key="cpuSpeedIndex" :value="cpuSpeed">{{cpuSpeed}}</md-option>
                                    </md-optgroup>
                                </md-select>
                                </md-field>                          
                            </div>
                            <div class="addNewItem__options__option" v-if="product.category != 'Monitory' && product.category != 'Akcesoria' && product.category != 'Części laptopowe' && product.category != 'Części komputerowe' && product.category != 'Tablety' && product.category != 'Telefony'">
                                <span class="md-title">Ilość rdzeni</span>
                                <md-field>
                                    <label for="manufacturers">Ilość rdzeni procesora</label>
                                    <md-select v-model="product.specs.coreNum.value">
                                        <md-option value="">Pole puste</md-option>
                                        <md-option value="1">1</md-option>
                                        <md-option value="2">2</md-option>
                                        <md-option value="4">4</md-option>
                                        <md-option value="6">6</md-option>
                                        <md-option value="8">8</md-option>
                                        <md-option value="Inny ilość">Inna ilość</md-option>                                                                                        
                                    </md-select>
                                </md-field>                  
                            </div>
                            <div class="addNewItem__options__option" v-if="product.category != 'Laptopy' && product.category != 'Monitory' && product.category != 'Akcesoria' && product.category != 'Części laptopowe' && product.category != 'Części komputerowe' && product.category != 'Tablety' && product.category != 'Telefony'">
                                <span class="md-title">Płyta Główna</span>
                                <md-field>
                                    <label>Nazwa płyty głównej</label>
                                    <md-input v-model="product.specs.motherboard.value"></md-input>
                                </md-field>                           
                            </div>
                            <div class="addNewItem__options__option" v-if="product.category === 'Telefony' || product.category === 'Tablety'">
                                <span class="md-title">Wbudowana pamięć</span>
                                <md-field>
                                    <label>Wbudowana pamięć</label>
                                    <md-input v-model="product.specs.productMemory.value"></md-input>
                                </md-field>                           
                            </div>
                            <div class="addNewItem__options__option" v-if="product.category === 'Telefony' || product.category === 'Tablety'">
                                <span class="md-title">Procesor (Telefon/Tablet)</span>
                                <md-field>
                                    <label>Procesor</label>
                                    <md-input v-model="product.specs.mobileProductProcessor.value"></md-input>
                                </md-field>                           
                            </div>
                            <div class="addNewItem__options__option" v-if="product.category === 'Telefony' || product.category === 'Tablety'">
                                <span class="md-title">Aparat przód</span>
                                <md-field>
                                    <label>Aparat przód</label>
                                    <md-input v-model="product.specs.cameraFront.value"></md-input>
                                </md-field>                           
                            </div>
                            <div class="addNewItem__options__option" v-if="product.category === 'Telefony' || product.category === 'Tablety'">
                                <span class="md-title">Aparat tył</span>
                                <md-field>
                                    <label>Aparat przód</label>
                                    <md-input v-model="product.specs.cameraBack.value"></md-input>
                                </md-field>                           
                            </div>
                            <div class="addNewItem__options__option" v-if="product.category === 'Telefony' || product.category === 'Tablety'">
                                <span class="md-title">Ładowarka</span>
                                <md-field>
                                    <label>Ładowarka</label>
                                    <md-input v-model="product.specs.powerSupply.value"></md-input>
                                </md-field>                           
                            </div>
                            <div class="addNewItem__options__option" v-if="product.category === 'Telefony' || product.category === 'Tablety'">
                                <span class="md-title">Funkcje</span>
                                <md-field>
                                    <label>Funkcje</label>
                                    <md-input v-model="product.specs.functions.value"></md-input>
                                </md-field>                           
                            </div>
                            <div class="addNewItem__options__option" v-if="product.category === 'Telefony' || product.category === 'Tablety'">
                                <span class="md-title">Transmisja danych</span>
                                <md-field>
                                    <label>Transmisja danych</label>
                                    <md-input v-model="product.specs.dataTransmision.value"></md-input>
                                </md-field>                           
                            </div>
                            <div class="addNewItem__options__option" v-if="product.category != 'Monitory' && product.category != 'Akcesoria' && product.category != 'Laptopy' && product.category != 'Części laptopowe' && product.category != 'Części komputerowe' && product.category != 'Tablety' && product.category != 'Telefony'">
                                <span class="md-title">Socket</span>
                                <md-field>
                                    <label>Socket</label>
                                    <md-input v-model="product.specs.socket.value"></md-input>
                                </md-field>                           
                            </div>
                            <div class="addNewItem__options__option" v-if="product.category != 'Monitory' && product.category != 'Akcesoria' && product.category != 'Części laptopowe' && product.category != 'Części komputerowe' && product.category != 'Tablety' && product.category != 'Telefony'">
                                <span class="md-title">Model karty graficznej</span>
                                <md-field>
                                    <label>Karta Graficzna</label>
                                    <md-input v-model="product.specs.graphics.value"></md-input>
                                </md-field>                           
                            </div>
                            <div class="addNewItem__options__option" v-if="product.category != 'Monitory' && product.category != 'Akcesoria' && product.category != 'Części laptopowe' && product.category != 'Części komputerowe' && product.category != 'Tablety' && product.category != 'Telefony'">
                                <span class="md-title">Rodzaj karty graficznej</span>
                                <md-field>
                                    <label for="networkStandard">Wybierz opcję</label>
                                    <md-select v-model="product.specs.graphicsType.value" name="networkStandard" id="networkStandard">
                                        <md-option value="">Nie dotyczy</md-option>
                                        <md-option value="Inny">Inny</md-option>
                                        <md-option value="Zintegrowana">Zintegrowana</md-option>
                                        <md-option value="Dedykowana">Dedykowana</md-option>
                                    </md-select>
                                </md-field>                            
                            </div>
                            <div class="addNewItem__options__option" v-if="product.category != 'Monitory' && product.category != 'Akcesoria' && product.category != 'Laptopy' && product.category != 'Części laptopowe' && product.category != 'Części komputerowe' && product.category != 'Tablety' && product.category != 'Telefony'">
                                <span class="md-title">Karta Sieciowa</span>
                                <md-field>
                                    <label for="networkStandard">Wybierz opcję</label>
                                    <md-select v-model="product.specs.networkStandard.value" name="networkStandard" id="networkStandard">
                                        <md-option value="">Nie dotyczy</md-option>
                                        <md-option value="Brak">Brak</md-option>
                                        <md-option value="LAN">LAN</md-option>
                                        <md-option value="WiFi">WiFi</md-option>
                                        <md-option value="LAN + WiFi">LAN + WiFi</md-option>
                                        <md-option value="LAN + WiFi na USB">LAN + WiFi na USB</md-option>
                                    </md-select>
                                </md-field>                            
                            </div>
                            <div class="addNewItem__options__option" v-if="product.category === 'Oprogramowanie' || product.category === 'Akcesoria' || product.category == 'Części laptopowe' || product.category == 'Części komputerowe'">
                                <span class="md-title">Rodzaj</span>
                                <md-field>
                                    <label>Rodzaj</label>
                                    <md-input v-model="product.specs.itemKind.value"></md-input>
                                </md-field>                           
                            </div>
                            <div class="addNewItem__options__option" v-if="product.category === 'Oprogramowanie' || product.category === 'Akcesoria' || product.category == 'Części laptopowe' || product.category == 'Części komputerowe'">
                                <span class="md-title">Przeznaczenie</span>
                                <md-field>
                                    <label>Przeznaczenie</label>
                                    <md-input v-model="product.specs.usageType.value"></md-input>
                                </md-field>                           
                            </div>
                            <div class="addNewItem__options__option" v-if="product.category === 'Oprogramowanie' || product.category === 'Akcesoria' || product.category == 'Części laptopowe' || product.category == 'Części komputerowe'">
                                <span class="md-title">Typ</span>
                                <md-field>
                                    <label>Typ</label>
                                    <md-input v-model="product.specs.productType.value"></md-input>
                                </md-field>                           
                            </div>
                            <div class="addNewItem__options__option" v-if="product.category === 'Oprogramowanie' || product.category === 'Akcesoria' || product.category == 'Części laptopowe' || product.category == 'Części komputerowe'">
                                <span class="md-title">Układ</span>
                                <md-field>
                                    <label>Układ</label>
                                    <md-input v-model="product.specs.structureType.value"></md-input>
                                </md-field>                           
                            </div>
                            <div class="addNewItem__options__option" v-if="product.category === 'Oprogramowanie' || product.category === 'Akcesoria' || product.category == 'Części laptopowe' || product.category == 'Części komputerowe'">
                                <span class="md-title">Moc</span>
                                <md-field>
                                    <label>Moc</label>
                                    <md-input v-model="product.specs.power.value"></md-input>
                                </md-field>                           
                            </div>
                            <div class="addNewItem__options__option" v-if="product.category === 'Oprogramowanie' || product.category === 'Akcesoria' || product.category == 'Części laptopowe' || product.category == 'Części komputerowe'">
                                <span class="md-title">Napięcie</span>
                                <md-field>
                                    <label>Napięcie</label>
                                    <md-input v-model="product.specs.tensity.value"></md-input>
                                </md-field>                           
                            </div>
                            <div class="addNewItem__options__option" v-if="product.category === 'Oprogramowanie' || product.category === 'Akcesoria' || product.category == 'Części laptopowe' || product.category == 'Części komputerowe'">
                                <span class="md-title">Natężenie</span>
                                <md-field>
                                    <label>Natężenie</label>
                                    <md-input v-model="product.specs.intensity.value"></md-input>
                                </md-field>                           
                            </div>
                            <div class="addNewItem__options__option" v-if="product.category === 'Oprogramowanie' || product.category === 'Akcesoria' || product.category == 'Części laptopowe' || product.category == 'Części komputerowe'">
                                <span class="md-title">Pojemność</span>
                                <md-field>
                                    <label>Pojemność</label>
                                    <md-input v-model="product.specs.capacity.value"></md-input>
                                </md-field>                           
                            </div>
                            <div class="addNewItem__options__option" v-if="product.category === 'Oprogramowanie' || product.category === 'Akcesoria' || product.category == 'Części laptopowe' || product.category == 'Części komputerowe'">
                                <span class="md-title">Standard</span>
                                <md-field>
                                    <label>Standard</label>
                                    <md-input v-model="product.specs.itemStandard.value"></md-input>
                                </md-field>                           
                            </div>
                        </div>
                    </div>
                    <div class="addNewItem__columns__column">
                        <div class="addNewItem__options">
                            <div class="addNewItem__options__option" v-if="product.category != 'Laptopy' && product.category != 'Tablety' && product.category != 'Telefony' && product.category != 'Monitory' &&  product.category != 'Akcesoria' && product.category != 'Części laptopowe' && product.category != 'Części komputerowe'">
                                <span class="md-title">Typ obudowy</span>
                                <md-field>
                                    <label for="manufacturers">Obudowa</label>
                                    <md-select v-model="product.specs.casing.value">
                                        <md-option value="">Nie dotyczy</md-option>
                                        <md-option value="Micro / Mini / Tiny">Micro / Mini / Tiny</md-option>
                                        <md-option value="USFF">USFF</md-option>
                                        <md-option value="SFF">SFF</md-option>
                                        <md-option value="Desktop">Desktop</md-option>
                                        <md-option value="Tower">Tower</md-option>
                                        <md-option value="All in one">All in one</md-option>                                                                                            
                                    </md-select>
                                </md-field>                  
                            </div>
                            <div class="addNewItem__options__option" v-if="product.category != 'Monitory' &&  product.category != 'Akcesoria' && product.category != 'Części laptopowe' && product.category != 'Części komputerowe'">
                                <span class="md-title">Dysk</span>
                                <md-field>
                                    <label for="os">Wybierz opcję</label>
                                    <md-select v-model="product.specs.hardDrive.value" name="hardDrive" id="hardDrive">
                                        <md-option value="">Nie dotyczy</md-option>
                                        <md-option value="Brak">Brak</md-option>
                                        <md-option v-for="hardDrive in attribs.hardDriveList.options" :key="hardDrive" :value="hardDrive">{{hardDrive}}</md-option>                                                                                                    
                                    </md-select>
                                </md-field>                        
                            </div>
                            <div class="addNewItem__options__option" v-if="product.category != 'Monitory' && product.category != 'Tablety' && product.category != 'Telefony' &&  product.category != 'Akcesoria' && product.category != 'Części laptopowe' && product.category != 'Części komputerowe'">
                                <span class="md-title">Dodatkowy dysk</span>
                                <md-field>
                                    <label for="osx">Wybierz opcję</label>
                                    <md-select v-model="product.specs.secondHardDrive.value" name="hardDrive" id="hardDrive">
                                        <md-option value="">Nie dotyczy</md-option>
                                        <md-option value="Brak">Brak</md-option>
                                        <md-option v-for="hardDrive in attribs.hardDriveList.options" :key="hardDrive" :value="hardDrive">{{hardDrive}}</md-option>                                                                                                    
                                    </md-select>
                                </md-field>                        
                            </div>
                            <div class="addNewItem__options__option" v-if="product.category != 'Monitory' && product.category != 'Tablety' && product.category != 'Telefony' &&  product.category != 'Akcesoria' && product.category != 'Części laptopowe' && product.category != 'Części komputerowe'">
                                <span class="md-title">Typ dysku</span>
                                <md-field>
                                    <label for="displayTouchable">Wybierz opcję</label>
                                    <md-select v-model="product.specs.typeOfHardrive.value" name="displayTouchable" id="displayTouchable">
                                        <md-option value="">Nie dotyczy</md-option>
                                        <md-option value="SSD">SSD</md-option>
                                        <md-option value="HDD">HDD</md-option>
                                        <md-option value="SSD+HDD">SSD+HDD</md-option>
                                    </md-select>
                                </md-field>                          
                            </div>
                            <div class="addNewItem__options__option" v-if="product.category != 'Monitory' &&  product.category != 'Akcesoria' && product.category != 'Części laptopowe' && product.category != 'Części komputerowe'">
                                <span class="md-title">Ilość pamięci RAM</span>
                                <md-field>
                                    <label for="ram">Wybierz opcję</label>
                                    <md-select v-model="product.specs.ram.value" name="ram" id="ram">
                                        <md-option value="">Nie dotyczy</md-option>
                                        <md-option value="Bez RAM">Bez RAM</md-option>
                                        <md-option v-for="ram in attribs.RAM.options" :key="ram" :value="ram">{{ram}}</md-option>
                                    </md-select>
                                </md-field>                           
                            </div>
                            <div class="addNewItem__options__option" v-if="product.category != 'Monitory' &&  product.category != 'Akcesoria' && product.category != 'Części laptopowe' && product.category != 'Części komputerowe'">
                                <span class="md-title">Typ pamięci RAM</span>
                                <md-field>
                                    <label for="manufacturers">Rodzaj pamięci RAM</label>
                                    <md-select v-model="product.specs.ramType.value">
                                        <md-option value="">Pole puste</md-option>
                                        <md-option value="DDR1">DDR1</md-option>
                                        <md-option value="DDR2">DDR2</md-option>
                                        <md-option value="DDR3">DDR3</md-option>
                                        <md-option value="DDR4">DDR4</md-option>
                                        <md-option value="Inny">Inny</md-option>                                                                                                      
                                    </md-select>
                                </md-field>                  
                            </div>
                            <div class="addNewItem__options__option" v-if="product.category != 'Monitory' && product.category != 'Tablety' && product.category != 'Telefony' &&  product.category != 'Akcesoria' && product.category != 'Części laptopowe' && product.category != 'Części komputerowe'">
                                <span class="md-title">Licencja</span>
                                <md-field>
                                    <label for="os">Wybierz opcję</label>
                                    <md-select v-model="product.specs.os.value" name="os" id="os">
                                        <md-option value="">Nie dotyczy</md-option>
                                        <md-option value="Brak">Brak</md-option>
                                        <md-option v-for="os in attribs.osList.options" :key="os" :value="os">{{os}}</md-option>                                                                                                            
                                    </md-select>
                                </md-field>                          
                            </div>
                            <div class="addNewItem__options__option" v-if="product.category != 'Monitory' && product.category != 'Tablety' && product.category != 'Telefony' &&  product.category != 'Akcesoria' && product.category != 'Części laptopowe' && product.category != 'Części komputerowe'">
                                <span class="md-title">Typ licencji</span>
                                <md-field>
                                    <label for="os">Wybierz opcję</label>
                                    <md-select v-model="product.specs.coaType.value" name="os" id="os">
                                        <md-option value="">Nie dotyczy</md-option>
                                        <md-option value="Inny">Inny</md-option>
                                        <md-option value="OEM">OEM</md-option>
                                        <md-option value="Refurbished">Refurbished</md-option>                                                                                                          
                                    </md-select>
                                </md-field>                          
                            </div>
                            <div class="addNewItem__options__option" v-if="product.category != 'Monitory' &&  product.category != 'Akcesoria' && product.category != 'Części laptopowe' && product.category != 'Części komputerowe'">
                                <span class="md-title">Zainstalowany system</span>
                                <md-field>
                                    <label>Zainstalowany system</label>
                                    <md-input v-model="product.specs.installedOS.value"></md-input>
                                </md-field>                           
                            </div>
                            <div class="addNewItem__options__option" v-if="product.category === 'Telefony' || product.category === 'Tablety'">
                                <span class="md-title">Pojemność akumulatora</span>
                                <md-field>
                                    <label>Pojemność akumulatora</label>
                                    <md-input v-model="product.specs.capacityOfBattery.value"></md-input>
                                </md-field>                           
                            </div>
                            <div class="addNewItem__options__option" v-if="product.category === 'Telefony' || product.category === 'Tablety'">
                                <span class="md-title">Slot karty pamięci</span>
                                <md-field>
                                    <label>Slot karty pamięci</label>
                                    <md-input v-model="product.specs.slotOfMemoryCard.value"></md-input>
                                </md-field>                           
                            </div>
                            <div class="addNewItem__options__option" v-if="product.category === 'Telefony' || product.category === 'Tablety'">
                                <span class="md-title">Nawigacja</span>
                                <md-field>
                                    <label>Nawigacja</label>
                                    <md-input v-model="product.specs.navigation.value"></md-input>
                                </md-field>                           
                            </div>
                            <div class="addNewItem__options__option" v-if="product.category === 'Telefony' || product.category === 'Tablety'">
                                <span class="md-title">Czujniki</span>
                                <md-field>
                                    <label>Czujniki</label>
                                    <md-input v-model="product.specs.sensors.value"></md-input>
                                </md-field>                           
                            </div>
                            <div class="addNewItem__options__option" v-if="product.category != 'Komputery' && product.category != 'Monitory' && product.category != 'Akcesoria' && product.category != 'Laptopy' && product.category != 'Części laptopowe' && product.category != 'Części komputerowe' && product.category != 'Telefony' && product.category != 'Tablety'">
                                <span class="md-title">Dźwięk</span>
                                <md-field>
                                    <label for="sound">Wybierz opcję</label>
                                    <md-select v-model="product.specs.sound.value" name="sound" id="sound">
                                        <md-option value="">Nie dotyczy</md-option>
                                        <md-option value='Zintegrowana'>Zintegrowana</md-option>
                                        <md-option value='Brak'>Brak</md-option>                                                                                                                
                                    </md-select>
                                </md-field>                          
                            </div>
                        </div>
                    </div>
                </div>
               <md-toolbar :md-elevation="1">
                    <span class="md-headline">Dodatkowe</span>
                </md-toolbar>
                <div class="addNewItem__columns">
                    <div class="addNewItem__columns__column">
                        <div class="addNewItem__options">
                            <div class="addNewItem__options__option" v-if="product.category != 'Komputery' && product.category != 'Monitory' &&  product.category != 'Akcesoria' && product.category != 'Części laptopowe' && product.category != 'Części komputerowe'">
                                <span class="md-title">Bateria</span>
                                <md-field>
                                    <label for="battery">Wybierz opcję</label>
                                    <md-select v-model="product.specs.battery.value" name="battery" id="battery" >
                                        <md-option value="">Nie dotyczy</md-option>
                                        <md-option value="Oryginalna, czas pracy minimum 0,5h (używana)">Oryginalna, czas pracy minimum 0,5h (używana)</md-option>
                                        <md-option value="Oryginalna, czas pracy od 1h do 2,5h (używana)">Oryginalna, czas pracy od 1h do 2,5h (używana)</md-option>
                                        <md-option value="Oryginalna, czas pracy minimum 1h (używana)">Oryginalna, czas pracy minimum 1h (używana)</md-option>
                                        <md-option value="Oryginalna, czas pracy minimum 1,5h (używana)">Oryginalna, czas pracy minimum 1,5h (używana)</md-option>
                                        <md-option value="Oryginalna, czas pracy minimum 2h (używana)">Oryginalna, czas pracy minimum 2h (używana)</md-option>
                                        <md-option value="Oryginalna, czas pracy minimum 4h (używana)">Oryginalna, czas pracy minimum 4h (używana)</md-option>
                                        <md-option value="Oryginalna, czas pracy od 2h do 4h (używana)">Oryginalna, czas pracy od 2h do 4h (używana)</md-option>
                                        <md-option value="Nowy zamiennik">Nowy zamiennik</md-option>
                                        <md-option value="Nowa oryginalna">Nowa oryginalna</md-option>
                                        <md-option value="Uszkodzona">Uszkodzona</md-option>
                                        <md-option value="Brak">Brak</md-option>
                                    </md-select>
                                </md-field>                           
                            </div>
                            <div class="addNewItem__options__option" v-if="product.category != 'Monitory' && product.category != 'Tablety' && product.category != 'Telefony' &&  product.category != 'Akcesoria' && product.category != 'Części laptopowe' && product.category != 'Części komputerowe'">
                                <span class="md-title">Złącza wewnętrzne</span>
                                <md-field>
                                    <label>Typ złącza</label>
                                    <md-input v-model="product.specs.insideConnections.value"></md-input>
                                </md-field>                           
                            </div>
                            <div class="addNewItem__options__option" v-if="product.category === 'Oprogramowanie' || product.category == 'Monitory' || product.category === 'Telefony' || product.category === 'Tablety' || product.category == 'Laptopy' || product.category == 'Części komputerowe' || product.category == 'Części laptopowe' || product.category == 'Akcesoria'">
                                <span class="md-title">Złącza zewnętrzne</span>
                                <md-field>
                                    <label>Typ złącza</label>
                                    <md-input v-model="product.specs.allConnectionsOutside.value"></md-input>
                                </md-field>                           
                            </div>
                            <div class="addNewItem__options__option" v-if="product.category != 'Akcesoria' &&  product.category != 'Monitory' && product.category != 'Laptopy' && product.category != 'Części laptopowe' && product.category != 'Części komputerowe' && product.category != 'Tablety' && product.category != 'Telefony'">
                                <span class="md-title">Złącza z przodu</span>
                                <md-field>
                                    <label>Typy złącza</label>
                                    <md-input v-model="product.specs.frontConnections.value"></md-input>
                                </md-field>                           
                            </div>
                            <div class="addNewItem__options__option" v-if="product.category != 'Akcesoria' &&  product.category != 'Monitory' && product.category != 'Laptopy' && product.category != 'Części laptopowe' && product.category != 'Części komputerowe' && product.category != 'Tablety' && product.category != 'Telefony'">
                                <span class="md-title">Złącza z tyłu</span>
                                <md-field>
                                    <label>Typy złącza</label>
                                    <md-input v-model="product.specs.backConnections.value"></md-input>
                                </md-field>                           
                            </div>
                            <div class="addNewItem__options__option" v-if="product.category != 'Akcesoria' &&  product.category != 'Monitory' && product.category != 'Laptopy' && product.category != 'Części laptopowe' && product.category != 'Części komputerowe' && product.category != 'Tablety' && product.category != 'Telefony'">
                                <span class="md-title">Złącza z boku</span>
                                <md-field>
                                    <label>Typy złącza</label>
                                    <md-input v-model="product.specs.sideConnections.value"></md-input>
                                </md-field>                           
                            </div>

                        </div>
                    </div>
                    <div class="addNewItem__columns__column">
                        <div class="addNewItem__options">
                            <div class="addNewItem__options__option" v-if="product.category === 'Oprogramowanie' || product.category === 'Akcesoria' || product.category === 'Części laptopowe' || product.category === 'Części komputerowe' || product.category === 'Laptopy' || product.category === 'Telefony' || product.category === 'Tablety' || product.subcategory === 'Zestawy i AIO' ">
                                <span class="md-title">Komunikacja</span>
                                <md-field>
                                    <label for="networkWireless">Wybierz opcję</label>
                                    <md-select v-model="product.specs.networkWireless.value" name="networkWireless" id="networkWireless">
                                        <md-option value="">Nie dotyczy</md-option>
                                        <md-option value="Przewodowa">Przewodowa</md-option>
                                        <md-option value="Bezprzewodowa">Bezprzewodowa</md-option>
                                        <md-option value="Brak">Brak</md-option>
                                        <md-option value="WiFi">WiFi</md-option>
                                        <md-option value="Modem 3G">Modem 3G</md-option>
                                        <md-option value="WiFi, Modem 3G">WiFi, Modem 3G</md-option>
                                        <md-option value="WiFi, Modem 4G">WiFi, Modem 4G</md-option>
                                        <md-option value="WiFi, Bluetooth">WiFi, Bluetooth</md-option>
                                        <md-option value="WiFi, Bluetooth, Modem 3G">WiFi, Bluetooth, Modem 3G</md-option>
                                        <md-option value="WiFi, Bluetooth, Modem 4G">WiFi, Bluetooth, Modem 4G</md-option>
                                        <md-option value="WiFi na USB">WiFi na USB</md-option>
                                        <md-option value="WiFi na USB, Modem 3G">WiFi na USB, Modem 3G</md-option>
                                        <md-option value="WiFi na USB, Bluetooth">WiFi na USB, Bluetooth</md-option>
                                        <md-option value="WiFi na USB, Bluetooth, Modem 3G">WiFi na USB, Bluetooth, Modem 3G</md-option>
                                        <md-option value="WiFi na USB, Bluetooth, Modem 4G">WiFi na USB, Bluetooth, Modem 4G</md-option>
                                    </md-select>
                                </md-field>                            
                            </div>
                            <div class="addNewItem__options__option" v-if="product.category != 'Monitory' && product.category != 'Tablety' && product.category != 'Telefony' &&  product.category != 'Akcesoria' && product.category != 'Części laptopowe' && product.category != 'Części komputerowe'">
                                <span class="md-title">Napęd</span>
                                <md-field>
                                    <label for="dvd">Wybierz opcję</label>
                                    <md-select v-model="product.specs.dvd.value" name="dvd" id="dvd">
                                        <md-option value="">Nie dotyczy</md-option>
                                        <md-option v-for="dvd in attribs.dvdOptions.options" :key="dvd" :value="dvd">{{dvd}}</md-option>
                                    </md-select>
                                </md-field>                          
                            </div>
                            <div class="addNewItem__options__option" v-if="product.category != 'Komputery' && product.category != 'Tablety' && product.category != 'Telefony' && product.category != 'Monitory' && product.category != 'Akcesoria' && product.category != 'Części laptopowe' && product.category != 'Części komputerowe'">
                                <span class="md-title">Klawiatura</span>
                                <md-field>
                                    <label for="networkWireless">Typ klawiatury</label>
                                    <md-select v-model="product.specs.keyboard.value" name="networkWireless" id="networkWireless">
                                        <md-option value="">Nie dotyczy</md-option>
                                        <md-option value="Brak">Brak</md-option>
                                        <md-option value="QWERTY PL">QWERTY PL</md-option>
                                        <md-option value="QWERTY PL, Numeryczna">QWERTY PL, Numeryczna</md-option>
                                        <md-option value="QWERTY PL, Podświetlana">QWERTY PL, Podświetlana</md-option>
                                        <md-option value="QWERTY PL, Podświetlana, Numeryczna">QWERTY PL, Podświetlana, Numeryczna</md-option>
                                        <md-option value="QWERTY PL (spolszczona naklejkami)">QWERTY PL (spolszczona naklejkami)</md-option>
                                        <md-option value="QWERTY PL (spolszczona naklejkami), Numeryczna">QWERTY PL (spolszczona naklejkami), Numeryczna</md-option>
                                        <md-option value="QWERTY PL (spolszczona naklejkami), Podświetlana">QWERTY PL (spolszczona naklejkami), Podświetlana</md-option>
                                        <md-option value="QWERTY PL (spolszczona naklejkami), Podświetlana, Numeryczna">QWERTY PL (spolszczona naklejkami), Podświetlana, Numeryczna</md-option>
                                        <md-option value="Wielojęzyczna">Wielojęzyczna</md-option>
                                        <md-option value="Wielojęzyczna, Podświetlana">Wielojęzyczna, Podświetlana </md-option>
                                        <md-option value="Wielojęzyczna, Podświetlana, Numeryczna">Wielojęzyczna, Podświetlana, Numeryczna</md-option>
                                    </md-select>
                                </md-field>                            
                            </div>
                            <div class="addNewItem__options__option" v-if="product.category === 'Oprogramowanie' || product.category === 'Akcesoria' || product.category == 'Części laptopowe' || product.category == 'Części komputerowe'">
                                <span class="md-title">Interfejs</span>
                                <md-field>
                                    <label>Interfejs</label>
                                    <md-input v-model="product.specs.interfaceType.value"></md-input>
                                </md-field>                           
                            </div>
                            <div class="addNewItem__options__option" v-if="product.category === 'Oprogramowanie' || product.category === 'Telefony' || product.category === 'Tablety' || product.category === 'Akcesoria' || product.category == 'Części laptopowe' || product.category == 'Części komputerowe'">
                                <span class="md-title">Kolor</span>
                                <md-field>
                                    <label>Kolor</label>
                                    <md-input v-model="product.specs.colorType.value"></md-input>
                                </md-field>                           
                            </div>
                        </div>
                    </div>
                </div>
                <md-toolbar :md-elevation="1">
                    <span class="md-headline">Wyświetlacz</span>
                </md-toolbar>
                <div class="addNewItem__columns">
                    <div class="addNewItem__columns__column">
                        <div class="addNewItem__options">
                            <div class="addNewItem__options__option" v-if="product.category != 'Komputery' && product.category != 'Tablety' && product.category != 'Telefony' && product.category != 'Części laptopowe' && product.category != 'Części komputerowe' &&  product.category != 'Akcesoria' || product.subcategory ==='Zestawy i AIO'">
                                <span class="md-title">Powłoka matrycy</span>
                                <md-field>
                                    <label for="displayType">Wybierz opcję</label>
                                    <md-select v-model="product.specs.displayType.value" name="displayType" id="displaydisplayType">
                                        <md-option value="">Nie dotyczy</md-option>
                                        <md-option value="Matowa">Matowa</md-option>
                                        <md-option value="Półmatowa">Półmatowa</md-option>
                                        <md-option value="Błyszcząca">Błyszcząca</md-option>
                                    </md-select>
                                </md-field>                          
                            </div>
                            <div class="addNewItem__options__option" v-if="product.category != 'Komputery' && product.category != 'Części laptopowe' && product.category != 'Części komputerowe' &&  product.category != 'Akcesoria' || product.subcategory ==='Zestawy i AIO' && product.category != 'Części laptopowe' && product.category != 'Części komputerowe'">
                                <span class="md-title">Rozdzielczość</span>
                                <md-field>
                                    <label for="displayResolution">Wybierz opcję</label>
                                    <md-select v-model="product.specs.displayResolution.value" name="displayResolution" id="displayResolution">
                                        <md-option value="">Nie dotyczy</md-option>
                                        <md-option v-for="res in attribs.ResolutionList.options" :key="res" :value="res">{{res}}</md-option>
                                    </md-select>
                                </md-field>                          
                            </div>
                            <div class="addNewItem__options__option" v-if="product.category != 'Komputery' && product.category != 'Części laptopowe' && product.category != 'Części komputerowe' &&  product.category != 'Akcesoria' || product.subcategory ==='Zestawy i AIO'">
                                <span class="md-title">Ekran dotykowy</span>
                                <md-field>
                                    <label for="displayTouchable">Wybierz opcję</label>
                                    <md-select v-model="product.specs.displayTouchable.value" name="displayTouchable" id="displayTouchable">
                                        <md-option value="">Nie dotyczy</md-option>
                                        <md-option value="Tak">Tak</md-option>
                                        <md-option value="Nie">Nie</md-option>
                                    </md-select>
                                </md-field>                          
                            </div>
                            <div class="addNewItem__options__option" v-if="product.category != 'Komputery' && product.category != 'Tablety' && product.category != 'Telefony' && product.category != 'Części laptopowe' && product.category != 'Części komputerowe' &&  product.category != 'Akcesoria' || product.subcategory ==='Zestawy i AIO'">
                                <span class="md-title">Jasność</span>
                                <md-field>
                                    <label>Jasność</label>
                                    <md-input v-model="product.specs.brightness.value"></md-input>
                                </md-field>                           
                            </div>
                            <div class="addNewItem__options__option" v-if="product.category != 'Komputery' && product.category != 'Tablety' && product.category != 'Telefony' && product.category != 'Części laptopowe' && product.category != 'Części komputerowe' &&  product.category != 'Akcesoria' || product.subcategory ==='Zestawy i AIO'">
                                <span class="md-title">Kontrast</span>
                                <md-field>
                                    <label>Kontrast</label>
                                    <md-input v-model="product.specs.contrast.value"></md-input>
                                </md-field>                           
                            </div>
                            <div class="addNewItem__options__option" v-if="product.category != 'Komputery' && product.category != 'Tablety' && product.category != 'Telefony' && product.category != 'Części laptopowe' && product.category != 'Części komputerowe' &&  product.category != 'Akcesoria' || product.subcategory ==='Zestawy i AIO'">
                                <span class="md-title">Kąt widzenia</span>
                                <md-field>
                                    <label>Kąt widzenia</label>
                                    <md-input v-model="product.specs.viewAngle.value"></md-input>
                                </md-field>                           
                            </div>
                            <div class="addNewItem__options__option" v-if="product.category === 'Monitory' && product.category != 'Tablety' && product.category != 'Telefony' &&  product.category != 'Akcesoria' && product.category != 'Części laptopowe' && product.category != 'Części komputerowe'">
                                <span class="md-title">Stopa w komplecie</span>
                                <md-field>
                                    <label for="displayTouchable">Wybierz opcję</label>
                                    <md-select v-model="product.specs.withFoot.value" name="displayTouchable" id="displayTouchable">
                                        <md-option value="">Nie dotyczy</md-option>
                                        <md-option value="Tak">Tak</md-option>
                                        <md-option value="Nie">Nie</md-option>
                                    </md-select>
                                </md-field>                          
                            </div>
                            <div class="addNewItem__options__option" v-if="product.category != 'Laptopy' && product.category != 'Tablety' && product.category != 'Telefony' && product.category != 'Komputery' &&   product.category != 'Akcesoria' && product.category != 'Części laptopowe' && product.category != 'Części komputerowe' || product.subcategory ==='Zestawy i AIO'">
                                <span class="md-title">Wbudowany głośnik</span>
                                <md-field>
                                    <label>Wbudowany głośnik</label>
                                    <md-input v-model="product.specs.hasSpeaker.value"></md-input>
                                </md-field>                           
                            </div>
                            <div class="addNewItem__options__option" v-if="product.category === 'Telefony' || product.category === 'Tablety'">
                                <span class="md-title">Rodzaj wyświetlacza (Telefon/Tablet)</span>
                                <md-field>
                                    <label>Rodzaj wyświetlacza</label>
                                    <md-input v-model="product.specs.mobileScreen.value"></md-input>
                                </md-field>                           
                            </div>

                        </div>
                    </div>
                    <div class="addNewItem__columns__column">
                        <div class="addNewItem__options">
                            <div class="addNewItem__options__option" v-if="product.category != 'Komputery' && product.category != 'Części laptopowe' && product.category != 'Części komputerowe' &&  product.category != 'Akcesoria' || product.subcategory ==='Zestawy i AIO'">
                                <span class="md-title">Przekątna ekranu</span>
                                <md-field>
                                    <label for="movie">Wybierz opcję</label>
                                    <md-select v-model="product.specs.displaySize.value" name="displaySize" id="displaySize">
                                        <md-option value="">Nie dotyczy</md-option>
                                        <md-option v-for="screenSize in attribs.screenSizes.options" :key="screenSize" :value="screenSize">{{screenSize}}</md-option>                                                                                                           
                                    </md-select>
                                </md-field> 
                                                           
                            </div>
                            <div class="addNewItem__options__option" v-if="product.category != 'Komputery' && product.category != 'Tablety' && product.category != 'Telefony' &&  product.category != 'Akcesoria' && product.category != 'Części laptopowe' && product.category != 'Części komputerowe' || product.subcategory ==='Zestawy i AIO'">
                                <span class="md-title">Kamera</span>
                                <md-field>
                                    <label for="movie">Wybierz opcję</label>
                                    <md-select v-model="product.specs.camera.value" name="camera" id="camera">
                                        <md-option value="">Nie dotyczy</md-option>
                                        <md-option value='Tak, wbudowana'>Tak, wbudowana</md-option>
                                        <md-option value='Tak, zewnętrzna na USB'>Tak, zewnętrzna na USB</md-option>
                                        <md-option value='Nie'>Nie</md-option>
                                        <md-option value='Opcjonalnie'>Opcjonalnie</md-option>                                                                                                             
                                    </md-select>
                                </md-field>                  
                            </div>
                            <div class="addNewItem__options__option" v-if="product.category != 'Komputery' && product.category != 'Tablety' && product.category != 'Telefony' &&  product.category != 'Akcesoria' && product.category != 'Części laptopowe' && product.category != 'Części komputerowe' || product.subcategory ==='Zestawy i AIO'">
                                <span class="md-title">Podświetlenie</span>
                                <md-field>
                                    <label for="movie">Wybierz opcję</label>
                                    <md-select v-model="product.specs.backlight.value" >
                                        <md-option value="">Nie dotyczy</md-option>
                                        <md-option value='LCD'>LCD</md-option>
                                        <md-option value='LED'>LED</md-option>
                                        <md-option value='OLED'>OLED</md-option>
                                        <md-option value='QLED'>QLED</md-option>                                                                                             
                                    </md-select>
                                </md-field>                  
                            </div>
                            <div class="addNewItem__options__option" v-if="product.category != 'Komputery' && product.category != 'Tablety' && product.category != 'Telefony' && product.category != 'Części laptopowe' && product.category != 'Części komputerowe' &&  product.category != 'Akcesoria' || product.subcategory ==='Zestawy i AIO'">
                                <span class="md-title">Typ matrycy</span>
                                <md-field>
                                    <label for="movie">Wybierz opcję</label>
                                    <md-select v-model="product.specs.screenType.value" >
                                        <md-option value="">Nie dotyczy</md-option>
                                        <md-option value='TN'>TN</md-option>
                                        <md-option value='IPS / PLS'>IPS / PLS</md-option>
                                        <md-option value='VA'>VA</md-option>
                                                                                           
                                    </md-select>
                                </md-field>                  
                            </div>
                            <div class="addNewItem__options__option" v-if="product.category != 'Laptopy' && product.category != 'Tablety' && product.category != 'Telefony' && product.category != 'Części laptopowe' && product.category != 'Części komputerowe' && product.category != 'Komputery' &&  product.category != 'Akcesoria' || product.subcategory ==='Zestawy i AIO'">
                                <span class="md-title">Pivot</span>
                                <md-field>
                                    <label for="movie">Pivot</label>
                                    <md-select v-model="product.specs.pivot.value" >
                                        <md-option value="">Nie dotyczy</md-option>
                                        <md-option value='Tak'>Tak</md-option>
                                        <md-option value='Nie'>Nie</md-option>
                                                                                           
                                    </md-select>
                                </md-field>                  
                            </div>
                            <div class="addNewItem__options__option" v-if="product.category != 'Laptopy' && product.category != 'Tablety' && product.category != 'Telefony' && product.category != 'Części laptopowe' && product.category != 'Części komputerowe' && product.category != 'Komputery' &&  product.category != 'Akcesoria' || product.subcategory ==='Zestawy i AIO'">
                                <span class="md-title">Regulacja wysokości</span>
                                <md-field>
                                    <label for="movie">Regulacja wysokości</label>
                                    <md-select v-model="product.specs.heightAdjustment.value" >
                                        <md-option value="">Nie dotyczy</md-option>
                                        <md-option value='Tak'>Tak</md-option>
                                        <md-option value='Nie'>Nie</md-option>
                                                                                           
                                    </md-select>
                                </md-field>                  
                            </div>
                            <div class="addNewItem__options__option" v-if="product.category != 'Laptopy' && product.category != 'Tablety' && product.category != 'Telefony' && product.category != 'Części laptopowe' && product.category != 'Części komputerowe' && product.category != 'Komputery' &&  product.category != 'Akcesoria' || product.subcategory ==='Zestawy i AIO'">
                                <span class="md-title">Regulacja kąta nachylania</span>
                                <md-field>
                                    <label for="movie">Regulacja kąta nachylenia</label>
                                    <md-select v-model="product.specs.angleAdjustment.value" >
                                        <md-option value="">Nie dotyczy</md-option>
                                        <md-option value='Tak'>Tak</md-option>
                                        <md-option value='Nie'>Nie</md-option>
                                                                                           
                                    </md-select>
                                </md-field>                  
                            </div>
                            <div class="addNewItem__options__option" v-if="product.category != 'Komputery' && product.category != 'Części laptopowe' && product.category != 'Części komputerowe' &&  product.category != 'Akcesoria' || product.subcategory ==='Zestawy i AIO'">
                                <span class="md-title">Stan ekranu</span>
                                <md-field>
                                    <label>Stan ekranu</label>
                                    <md-input v-model="product.specs.screenCondition.value"></md-input>
                                </md-field>                           
                            </div>

                        </div>
                    </div>
                </div>
                <md-toolbar :md-elevation="1">
                    <span class="md-headline">Waga i wymiary</span>
                </md-toolbar>
                <div class="addNewItem__columns">
                    <div class="addNewItem__columns__column">
                        <div class="addNewItem__options">
                            <div class="addNewItem__options__option">
                                <span class="md-title">Wysokość</span>
                                <md-field>
                                    <label>Wysokość (CM)</label>
                                    <md-input v-model="product.specs.itemHeight.value"></md-input>
                                </md-field>                           
                            </div>
                        </div>
                        <div class="addNewItem__options">
                            <div class="addNewItem__options__option">
                                <span class="md-title">Szerokość</span>
                                <md-field>
                                    <label>Szerokość (CM)</label>
                                    <md-input v-model="product.specs.itemWidth.value"></md-input>
                                </md-field>                           
                            </div>
                        </div>
                        <div class="addNewItem__options">
                            <div class="addNewItem__options__option">
                                <span class="md-title">Długość</span>
                                <md-field>
                                    <label>Długość (CM)</label>
                                    <md-input v-model="product.specs.itemLength.value"></md-input>
                                </md-field>                           
                            </div>
                        </div>
                        <div class="addNewItem__options">
                            <div class="addNewItem__options__option">
                                <span class="md-title">Głębokość</span>
                                <md-field>
                                    <label>Głębokość (CM)</label>
                                    <md-input v-model="product.specs.itemDepth.value"></md-input>
                                </md-field>                           
                            </div>
                        </div>
                        <div class="addNewItem__options">
                            <div class="addNewItem__options__option">
                                <span class="md-title">Waga</span>
                                <md-field :class="{'md-invalid': errors.weightError}">
                                    <label>Waga produktu</label>
                                    <span class="md-prefix">Kg</span>
                                    <md-input v-model="product.specs.weight.value"></md-input>
                                    <span class="md-error">Waga musi być wypełniona w celu wyliczenia ilości przesyłek kurierskich.</span>
                                </md-field>                           
                            </div>
                        </div>
                    </div>
                </div>
                <md-toolbar :md-elevation="1">
                    <span class="md-headline">Inne</span>
                </md-toolbar>
                <div class="addNewItem__columns__column">
                    <div class="addNewItem__options">
                            <div class="addNewItem__options__option">
                                <span class="md-title">Informacje dodatkowe</span>
                                <md-field>
                                    <label>Wprowadź informację</label>
                                    <md-input v-model="product.specs.additionalInfo.value"></md-input>
                                </md-field>                           
                            </div>
                    </div>
                </div>
                <div class="addNewItem__columns__column">
                    <div class="addNewItem__options">
                            <div class="addNewItem__options__option">
                                <span class="md-title">W zestawie</span>
                                <md-field>
                                    <label>Napisz co jest w zestawie</label>
                                    <md-input v-model="product.specs.inPack.value"></md-input>
                                </md-field>                           
                            </div>
                    </div>
                </div>
                <div class="addNewItem__columns__column">
                    <div class="addNewItem__options">
                                <span class="md-title">Gwarancja</span>
                                <md-field>
                                    <label for="displayTouchable">Wybierz typ gwarancji</label>
                                    <md-select v-model="product.specs.warrantyType.value" name="displayTouchable" id="displayTouchable">
                                        <md-option value="">Nie dotyczy</md-option>
                                        <md-option value="Brak">Brak</md-option>
                                        <md-option value="1 miesiąc">1 miesiąc</md-option>
                                        <md-option value="3 miesiące">3 miesiące</md-option>
                                        <md-option value="6 miesięcy">6 miesięcy</md-option>
                                        <md-option value="12 miesięcy">12 miesięcy</md-option>
                                        <md-option value="24 miesiące">24 miesiące</md-option>
                                        <md-option value="36 miesięcy">36 miesięcy</md-option>
                                        <md-option value="Producenta – 12 miesięcy">Producenta – 12 miesięcy</md-option>
                                        <md-option value="Producenta – 24 miesięcy">Producenta – 24 miesięcy</md-option>
                                        <md-option value="Producenta – 36 miesięcy">Producenta – 36 miesięcy</md-option>
                                    </md-select>
                                </md-field>    

                    </div>
                </div>
                
            </md-step>
            <md-step id="fourth" md-label="Podsumowanie">
                <div class="addNewItem__itemDescription">
                    <div class="addNewItem__itemDescription__menu"><md-button class="md-primary" @click="addDescSection">Dodaj sekcje opisu..</md-button></div>
                    <md-divider></md-divider>
                    <div class="addNewItem__itemDescription__section" v-for="(section,sectionIndx) in product.descSections" :key="sectionIndx">
                        <span class="md-title">Sekcja {{sectionIndx+1}}</span>
                        <div class="addNewItem__itemDescription__section__form">
                            <span class="md-body-2">Nagłówek</span>
                            <md-field>
                                <label>Tytuł sekcji</label>
                                <md-input v-model="product.descSections[sectionIndx].header">></md-input>
                            </md-field>
                            <span class="md-body-2">Tekst sekcji</span>
                            <md-field>
                                <label>Szczegółowy opis sekcji</label>
                                <md-textarea name="test" v-model="product.descSections[sectionIndx].text"></md-textarea>  
                            </md-field> 
                            <span class="md-body-2">Link zdjęcia</span>
                            <md-field>
                                <label>URL</label>
                                <md-input v-model="product.descSections[sectionIndx].url">></md-input>
                            </md-field>
                            <md-field >
                                <label>Przeglądaj, aby uzyskać link..</label>
                                <md-file @md-change="getLink($event,sectionIndx)" accept="image/*" />
                            </md-field>
                            <span class="md-body-2">SEO Keyword</span>
                            <md-field>
                                <label>Opis alternatywny zdjęcia</label>
                                <md-input v-model="product.descSections[sectionIndx].keyword">></md-input>
                            </md-field>
                            <md-button class="md-accent" @click="product.descSections.splice(sectionIndx,1)">Usuń sekcję {{sectionIndx+1}}</md-button>
                        </div>
                        <md-divider></md-divider>
                    </div>

                </div>
                <md-switch v-model="product.promotion" @change="changePrices()">Promocja</md-switch>
                <md-switch v-model="product.active">Rozpocznij sprzedaż </md-switch>
                <div class="addNewItem__nextStep">
                    <md-button class="md-raised md-primary" v-show="!loading && item === undefined" @click="validateItemFormula">Utwórz przedmiot</md-button>
                    <md-button class="md-raised" v-show="!loading && item !== undefined && product.isDuplicate" @click="goToMainProduct">Przejdź do produktu nadrzędnego</md-button>
                    <md-button class="md-raised" v-show="!loading && item !== undefined" @click="goToAuction">Przejdź do aukcji</md-button>
                    <md-button class="md-raised" v-show="!loading && item !== undefined" @click="askForDuplicateAsMain">Duplikuj jako główny</md-button>
                    <md-button class="md-raised" v-show="!loading && item !== undefined && product.isDuplicate===false" @click="askForDuplicate">Duplikuj jako wariant</md-button>
                    <!-- <md-button class="md-raised" v-show="!loading && item !== undefined" @click="askForBaselinkerUpdate">Aktualizuj Baselinkera</md-button> -->
                    <md-button class="md-raised" v-show="!loading && item !== undefined" @click="askForSellasistUpdate">Aktualizuj Sellasist</md-button>
                    <md-button class="md-raised md-accent" v-show="!loading && item !== undefined" @click="prepareDelete">Usuń produkt</md-button>
                    <md-button class="md-raised md-primary" v-show="!loading && item !== undefined" @click="validateItemFormulaEditMode">Zatwierdź zmiany</md-button>
                </div>
                <div class="loadingInfo" v-show="loading"><span class="md-caption">{{loadingInfo}}</span></div>
                <md-progress-bar v-show="loading" md-mode="indeterminate"></md-progress-bar>
                <div class="addNewItem__productInfo" v-show="!loading && item !== undefined">
                    <span v-if="product.sellasistProductID != null" class="md-caption">Sellasist ID: {{product.sellasistProductID}}</span>
                    <span v-if="product.baselinkerProductID != null" class="md-caption">Baselinker ID: {{product.baselinkerProductID}}</span>
                    <span class="md-caption">ID Produktu: {{product.uniqueID}}</span>
                    <span class="md-caption" v-if="product.motherProductID.length===0">Produkt nadrzędny.</span>
                    <span class="md-caption" v-if="product.motherProductID.length>0">Wariant dla produktu: {{product.motherProductID}}</span>
                    <md-button class="md-primary" @click="downloadVariantsInfo" v-if="product.variantsID.length>0 && !loading &&!lockVariantDownload">Pobierz informację o wariantach</md-button>
                </div>
                <div class="addNewItem__childProducts" v-if="variantsData.length>0 && item !== undefined && !loading">
                    <div class="addNewItem__childProducts__name"><span class="md-body-2">Znalezione warianty ({{variantsData.length}})</span></div>
                    <div class="addNewItem__childProducts__container" v-for="(child,childIndx) in variantsData" :key="childIndx">
                        <div class="addNewItem__childProducts__container__image"><img :src="child.mainImage.url"></div><div class="addNewItem__childProducts__container__childName"><span class="md-body-2">{{child.name}}</span><span class="md-caption spaceLeft" v-if="child.snippet.length>0">     {{child.snippet[0]}} - {{child.snippet[1]}} - {{child.snippet[2]}}</span></div><div><md-button class="md-primary" @click="loadProductFromVariant(childIndx)">Wczytaj</md-button></div>
                    </div>
                </div>

            </md-step>
            </md-steppers>
        </div>


            <md-dialog-confirm
            :md-active.sync="askForCreatingMain"
            md-title="Duplikowanie przedmiotu"
            md-content="Czy na pewno chcesz zduplikować ten przedmiot?"
            md-confirm-text="TAK"
            md-cancel-text="COFNIJ"
            @md-cancel="onCancelAddItem"
            @md-confirm="duplicateAsMain" />


            <md-dialog-confirm
            :md-active.sync="askForDelete"
            md-title="Usuwanie przedmiotu"
            md-content="Czy na pewno chcesz usunąć przedmiot? Usunięcie produktu nadrzędnego spowoduje utratę zdjęć wspólnych w produktach mu podległych."
            md-confirm-text="TAK"
            md-cancel-text="COFNIJ"
            @md-cancel="onCancelAddItem"
            @md-confirm="onConfirmDeleteItem" />

            <md-dialog-confirm
            :md-active.sync="askForAdd"
            md-title="Dodawanie przedmiotu"
            md-content="Czy jesteś pewien, że chcesz utworzyć ten przedmiot?"
            md-confirm-text="TAK"
            md-cancel-text="PRZERWIJ"
            @md-cancel="onCancelAddItem"
            @md-confirm="onConfirmAddItem" />
            <md-dialog-confirm
            :md-active.sync="askForEdit"
            md-title="Uaktualnienie przedmiotu"
            md-content="Czy na pewno chcesz zaktualizować przedmiot?"
            md-confirm-text="TAK"
            md-cancel-text="COFNIJ"
            @md-cancel="onCancelAddItem"
            @md-confirm="onConfirmEditItem" />
            <md-snackbar :md-position="'center'" :md-active.sync="showSnackbar" md-persistent>
                <span>{{snackbarMsg}}</span>
            </md-snackbar>

    <md-dialog :md-active.sync="showDialogAddons">
      <md-dialog-title>Zapisane dodatki</md-dialog-title>

      <md-tabs md-dynamic-height>
        <md-tab md-label="Wczytaj dodatek z bazy danych">
            <span class="md-caption">Wybierz dodatek, który chcesz wgrać do przedmiotu, a następnie kliknij przycisk "Dodaj".</span>
            <div class="addonTab">
                <md-checkbox v-if="addonsFromDb.length>0" v-model="crudOptionsAddons">Tryb edycji</md-checkbox>
                <div class="addonTab__header"></div>
                 <div class="addonTab__item" v-for="(addon,addIndx) in addonsFromDb" :key="addIndx">
                     <div class="addonTab__item__name">
                         <span class="md-title">{{addon.name}} <span class="md-caption">({{addon.options.length}})</span> </span>
                         <md-button class="md-dense md-primary" @click="addExistingAddon(addon)" v-if="addon.options.length>0">Dodaj do produktu</md-button>
                         <md-button class="md-dense md-accent" v-if="crudOptionsAddons" @click="deleteAddonFromDatabase(addon)">Usuń z bazy</md-button>
                     </div>
                     <div class="addonTab__item__options">
                         <div class="addonTab__item__options__option" v-for="(option,opIndex) in addon.options" :key="opIndex">
                            <span class="md-body-1" v-if="!crudOptionsAddons">{{option.name}}</span>
                            <span class="md-body-1" v-if="crudOptionsAddons"> Nazwa: </span>
                            <input type="text"  class="editAddonInput--long" @change="inputCrudAddonEdited('number',option,addon)" placeholder="Nazwa" v-model="option.name" v-if="crudOptionsAddons">
                            <span class="md-caption" v-if="!addon.percantagePrice && !crudOptionsAddons"> +{{option.price}} PLN</span>
                            <span class="md-body-1" v-if="crudOptionsAddons && !addon.percantagePrice"> Kwota: </span>
                            <span class="md-body-1" v-if="crudOptionsAddons && addon.percantagePrice"> Procent: </span>
                            <input type="number" class="editAddonInput" @change="inputCrudAddonEdited('number',option,addon)" placeholder="Kwota" v-model="option.price" v-if="crudOptionsAddons && !addon.percantagePrice">
                            <span class="md-caption" v-if="crudOptionsAddons && !addon.percantagePrice"> PLN</span>
                            <span class="md-caption" v-if="addon.percantagePrice && !crudOptionsAddons"> +{{option.percantage}}% od ceny</span>
                            <input type="number" class="editAddonInput" @change="inputCrudAddonEdited('percent',option,addon)" placeholder="Procent" v-model="option.percantage" v-if="crudOptionsAddons && addon.percantagePrice">
                            <img class="closeButton"  src="../assets/svg/fi-rr-cross-circle.svg" v-if="crudOptionsAddons" @click="deleteOptionFromAddonDb(addon,opIndex)">
                            <img :class="{closeButton: true, disabledOpt: opIndex === 0}"  src="../assets/svg/fi-rr-arrow-small-up.svg" v-if="crudOptionsAddons && opIndex !== 0" @click="optionArrayMove(addon.options,opIndex,opIndex-1,addon)">
                            <img :class="{closeButton: true, disabledOpt: opIndex === addon.options.length-1}"  src="../assets/svg/fi-rr-arrow-small-down.svg" v-if="crudOptionsAddons && opIndex < addon.options.length-1" @click="optionArrayMove(addon.options,opIndex,opIndex+1,addon)">
                         </div>
                     </div>
                     <div class="addonTab__item__manage" v-if="crudOptionsAddons">
                        <div><span class="md-body-2">Nowa opcja</span></div>
                        <div class="addonTab__item__manage__inputs">
                            <div class="addonTab__item__manage__inputs__input">
                                <md-field>
                                    <label>Nazwa</label>
                                    <md-input v-model="newAddonInputsVModelArray[addIndx].name"></md-input>
                                </md-field>
                            </div>
                            <div class="addonTab__item__manage__inputs__input" v-if="!addon.percantagePrice">
                                <md-field>
                                    <label>Cena</label>
                                    <span class="md-prefix">PLN</span>
                                    <md-input type="number" v-model="newAddonInputsVModelArray[addIndx].price"></md-input>
                                </md-field>
                            </div>
                            <div class="addonTab__item__manage__inputs__input" v-if="addon.percantagePrice">
                                <md-field>
                                    <label>Procent ceny</label>
                                    <span class="md-prefix">%</span>
                                    <md-input type="number" v-model="newAddonInputsVModelArray[addIndx].percantage"></md-input>
                                </md-field>
                            </div>
                            <div class="addonTab__item__manage__inputs__input" v-if="newAddonInputsVModelArray[addIndx].name.length>0 && newAddonInputsVModelArray[addIndx].price > 0 && !addon.percantagePrice">
                                <md-button class="md-raised" @click="updateAddonOption(addon,newAddonInputsVModelArray[addIndx])">Dodaj</md-button>
                            </div>
                            <div class="addonTab__item__manage__inputs__input" v-if="newAddonInputsVModelArray[addIndx].name.length>0 && newAddonInputsVModelArray[addIndx].percantage > 0 && addon.percantagePrice">
                                <md-button class="md-raised" @click="updateAddonOption(addon,newAddonInputsVModelArray[addIndx])">Dodaj</md-button>
                            </div>

                        </div>

                     </div>
                 </div>
            </div>
        </md-tab>
        <md-tab md-label="Dodaj dodatek do bazy danych">
            <div class="addonTab">
                <span class="md-caption">Utwórz nowy dodatek. Opcja rozbudowy będzie dostępna w przyszłości.</span>
                <div class="addonTab__addNew">
                    <span class="md-title">Nazwa dodatku</span>
                    <md-field>
                        <label>Nazwa dodatku</label>
                        <md-input v-model="newAddonScheme.name" required></md-input>
                    </md-field>
                    <md-switch v-model="newAddonScheme.percantagePrice">Procent od ceny</md-switch>
                </div>
                <span class="md-title">Opcje rozbudowy</span>
                <div class="addonTab__addNewOption">
                    <div class="addonTab__addNewOption__optionCreator"> 
                        <md-field>
                            <label>Nazwa</label>
                            <md-input v-model="addonInputHolder.name" required></md-input>
                        </md-field>
                    </div>
                    <div class="addonTab__addNewOption__optionCreator" v-if="!newAddonScheme.percantagePrice"> 
                        <md-field>
                            <label>Koszt PLN</label>
                            <md-input type="number" v-model="addonInputHolder.price" required></md-input>
                        </md-field>
                    </div>
                    <div class="addonTab__addNewOption__optionCreator" v-if="newAddonScheme.percantagePrice"> 
                        <md-field>
                            <label>Procent ceny</label>
                            <md-input type="number" v-model="addonInputHolder.percantage" required></md-input>
                        </md-field>
                    </div>
                    <div class="addonTab__addNewOption__optionCreator">
                         <md-button class="md-primary" v-if="!newAddonScheme.percantagePrice" :disabled="addonInputHolder.name.length == 0" @click="addToAddonScheme('normal')">Dodaj</md-button>
                         <md-button class="md-accent" v-if="newAddonScheme.percantagePrice" :disabled="addonInputHolder.name.length == 0 || addonInputHolder.percantage <= 0" @click="addToAddonScheme('percent')">Dodaj</md-button>
                    </div>
                </div>
                <span class="md-title" v-if="newAddonScheme.options.length>0">Stworzone opcje</span>
                <div class="addonTab__scheme__schemeOptions" v-if="newAddonScheme.options.length>0">
                    <div class="addonTab__addNewOption__schemeOptions__options" v-for="(createdOption,createdOptionIndx) in newAddonScheme.options" :key="createdOptionIndx"> 
                            <span class="md-body-2" v-if="!newAddonScheme.percantagePrice">{{createdOption.name}} - {{createdOption.price}} PLN </span>
                            <span class="md-body-2" v-if="newAddonScheme.percantagePrice">{{createdOption.name}} - {{createdOption.percantage}} % od ceny </span>
                            <img class="closeButton"  src="../assets/svg/fi-rr-cross-circle.svg" @click="newAddonScheme.options.splice(createdOptionIndx,1)">
                    </div>
                     
                </div>
            </div>

        </md-tab>

      </md-tabs>

      <md-dialog-actions>
          <md-button class="md-accent" v-if="newAddonScheme.options.length> 0 && newAddonScheme.name.length>0" @click="saveAddonToDb()">Zapisz</md-button>
        <md-button class="md-primary" @click="toggleAddonLoadTab()">Zamknij</md-button>
      </md-dialog-actions>
    </md-dialog>
    <md-dialog-confirm
      :md-active.sync="duplicateWindow"
      md-title="Czy na pewno chcesz zduplikować przedmiot?"
      md-content="Program utworzy teraz duplikat ostatniego zapisu tego przedmiotu. Doda go również do magazynu Sellasist."
      md-confirm-text="Zatwierdź"
      md-cancel-text="Cofnij"
      @md-cancel="onCancelAddItem"
      @md-confirm="duplicate" />

    <md-dialog-confirm
      :md-active.sync="baselinkerUpdateAsk"
      md-title="Czy na pewno chcesz zaktualizować produkt w magazynie Baselinkera?"
      md-content="Aktualizacja nadpisuje stan magazynowy. Zanim rozpoczniesz, zapisz zmiany standardowym sposobem, a następnie zatwierdź uruchomienie synchronizacji. Nieprzemyślana aktualizacja może mieć konsekwencje."
      md-confirm-text="Zatwierdź"
      md-cancel-text="Cofnij"
      @md-cancel="onCancelAddItem"
      @md-confirm="updateBaselinker" />

    <md-dialog-confirm
      :md-active.sync="sellasistUpdateAsk"
      md-title="Czy na pewno chcesz zaktualizować produkt w magazynie Sellasist?"
      md-content="Aktualizacja nadpisuje stan magazynowy. Zanim rozpoczniesz, zapisz zmiany standardowym sposobem, a następnie zatwierdź uruchomienie synchronizacji. Nieprzemyślana aktualizacja może mieć konsekwencje."
      md-confirm-text="Zatwierdź"
      md-cancel-text="Cofnij"
      @md-cancel="onCancelAddItem"
      @md-confirm="updateSellasist" />

    <md-dialog :md-active.sync="showUploadPopup" >
      <md-dialog-title>Upload</md-dialog-title>

      <md-tabs md-dynamic-height>
        <md-tab md-label="Uzyskaj link">
            <div><span class="md-subheading">1. Wybierz plik</span></div>
            <div><span class="md-body-2"></span></div>
            <div>
                <md-field >
                    <label>Przeglądaj</label>
                    <md-file v-model="imageToDescription" accept="image/*" />
                </md-field>
            </div>
        </md-tab>
      </md-tabs>

      <md-dialog-actions>
        <md-button class="md-primary" @click="showUploadPopup = false">Zamknij</md-button>
      </md-dialog-actions>
    </md-dialog>

    </div>
    
</template>

<script>
import {firebase, db, storage} from "../configs/firebase";
import imageCompression from 'browser-image-compression';
import axios from 'axios';
import loader from '../components/loader.vue';

export default {
  data() {
    return {
        askForCreatingMain: false,
        imageToDescription: "",
        showUploadPopup: false,
        newAddonInputsVModelArray: [],
        crudOptionsAddons: false,
        addonInputHolder:
        {
            name: "",
            price: 100,
            percantage: 10
        },
        newAddonScheme: {
            name: "",
            id: "",
            percantagePrice: false,
            options: []
        },
        preparedAddons: [],
        lockVariantDownload: false,
        variantsData: [],
        baselinkerUpdateAsk: false,
        sellasistUpdateAsk: false,
        duplicateWindow: false,
        // Whole Product
        product: {
            uniqueID: "",
            deliveryTime: 24,
            inpost: {
                allowed: false,
            },
            isDuplicate: false,
            active: false,
            promotion: false,
            addonsIds: [],
            motherProductID: "",
            variantsID: [],
            name: "", // Product name
            category: "Laptopy", // Item category name,
            subcategory: "", // Item subcategory name,
            qualityClass: "Nowy", // Class of product quality
            isUnique: false, // True if product is unique, one of a kind
            basePrice: 500, // Base price of product
            priceBeforePromotion: 0, // Price before promtion
            quantity: 1, // Quanity of product
            manufacturer: "",
            model: "",
            linkToItem: "",
            metaDescription: "Najtańsze laptopy oraz komputery poleasingowe.",
            keywords: ["kompre","laptopy","komputery","komputery używane","monitory","najtańsze laptopy","komputer poleasingowy","laptop poleasingowy","sklep komputerowy","outlet komputerowy"], // SEO Keywords
            imgAlt: ["jaki komputer do biura", "najtańsze laptopy","komputer poleasingowy","laptop poleasingowy","tani laptop","komputery dla szkół","sklep komputerowy","tanie monitory poleasingowe","komputer gamingowy", "laptop ssd"], // SEO Meta tags
            description: ``, // Product Description
            descSections: [],
            mainImage: {
                    url: "",
                    indx: 0,
                    keyword: ""
            }, // Main Image
            imageGallery: [],
            innerCode: "",
            location: "",
            sku: "",
            ean: "",
            baselinkerProductID: "",
            sellasistProductID: "",
            baselinkerPreparedImages: [],
            sellasistPreparedImages: [],
            snippet: ['8GB RAM', 'i3-2100', 'DVD'],
            specs: {
                itemCondition: {
                    name: 'Kondycja',
                    value: 'Nowy',
                    place: 1,
                    type: 'Basic'
                },
                processorType: {
                    name: 'Seria procesora',
                    value: '',
                    place: 4,
                    type: 'Basic'
                },
                screenCondition: {
                    name: 'Stan ekranu',
                    value: '',
                    place: 5,
                    type: 'Basic'
                },
                casing: {
                    name: 'Obudowa',
                    value: '',
                    place: 6,
                    type: 'Basic'
                },
                casingCondition: {
                    name: 'Stan obudowy',
                    value: '',
                    place: 7,
                    type: 'Basic'
                },
                warrantyType: {
                    name: "Gwarancja",
                    value: "",
                    place: 8,
                    type: 'Basic'
                },
                processor: {
                    name: 'Procesor',
                    value: '',
                    place: 1,
                    type: 'Specs'
                },
                mobileProductProcessor: {
                    name: 'Procesor',
                    value: '',
                    place: 2,
                    type: 'Specs'
                },
                cpuSpeed: {
                    name: 'Taktowanie',
                    value: '',
                    place: 3,
                    type: 'Specs'
                },
                coreNum: {
                    name: 'Ilość rdzeni',
                    value: '',
                    place: 4,
                    type: 'Specs'
                },
                socket: {
                    name: 'Gniazdo procesora',
                    value: '',
                    place: 5,
                    type: 'Specs'
                },
                motherboard: {
                    name: 'Płyta główna',
                    value: '',
                    place: 6,
                    type: 'Specs'
                },
                ram: {
                    name: 'Ilość pamięci RAM',
                    value: '',
                    place: 7,
                    type: 'Specs'
                },
                ramType: {
                    name: 'Typ pamięci RAM',
                    value: '',
                    place: 8,
                    type: 'Specs'
                },
                productMemory: {
                    name: 'Wbudowana pamięć',
                    value: '',
                    place: 9,
                    type: 'Specs'
                },
                hardDrive: {
                    name: 'Dysk',
                    value: '',
                    place: 10,
                    type: 'Specs'
                },
                secondHardDrive: {
                    name: 'Dodatkowy dysk',
                    value: '',
                    place: 11,
                    type: 'Specs'
                },
                typeOfHardrive: {
                    name: 'Typ dysku',
                    value: '',
                    place: 12,
                    type: 'Specs'
                },
                os: {
                    name: 'Licencja',
                    value: '',
                    place: 13,
                    type: 'Specs'
                },
                coaType: {
                    name: 'Typ licencji',
                    value: '',
                    place: 14,
                    type: 'Specs'
                },
                installedOS: {
                    name: "Zainstalowany system",
                    value: "",
                    place: 15,
                    type: 'Specs'
                },
                displayTouchable: {
                    name: 'Ekran dotykowy',
                    value: '',
                    place: 16,
                    type: 'Specs'
                },
                displayResolution: {
                    name: 'Rozdzielczość ekranu',
                    value: '',
                    place: 17,
                    type: 'Specs'
                },
                displaySize: {
                    name: 'Przekątna ekranu',
                    value: '',
                    place: 18,
                    type: 'Specs'
                },
                mobileScreen: {
                    name: 'Rodzaj wyświetlacza',
                    value: '',
                    place: 19,
                    type: 'Specs'
                },
                displayType: {
                    name: 'Powłoka matrycy',
                    value: '',
                    place: 20,
                    type: 'Specs'
                },
                backlight: {
                    name: 'Podświetlenie',
                    value: '',
                    place: 21,
                    type: 'Specs'
                },
                screenType: {
                    name: 'Typ matrycy',
                    value: '',
                    place: 22,
                    type: 'Specs'
                },
                itemKind: {
                    name: 'Rodzaj',
                    value: '',
                    place: 23,
                    type: 'Specs'
                },
                usageType: {
                    name: 'Przeznaczenie',
                    value: '',
                    place: 24,
                    type: 'Specs'
                },
                productType: {
                    name: 'Typ',
                    value: '',
                    place: 25,
                    type: 'Specs'
                },
                interfaceType: {
                    name: 'Interfejs',
                    value: '',
                    place: 26,
                    type: 'Specs'
                },
                colorType: {
                    name: 'Kolor',
                    value: '',
                    place: 27,
                    type: 'Specs'
                },
                structureType: {
                    name: 'Układ',
                    value: '',
                    place: 28,
                    type: 'Specs'
                },
                power: {
                    name: 'Moc',
                    value: '',
                    place: 29,
                    type: 'Specs'
                },
                tensity: {
                    name: 'Napięcie',
                    value: '',
                    place: 30,
                    type: 'Specs'
                },
                intensity: {
                    name: 'Natężenie',
                    value: '',
                    place: 31,
                    type: 'Specs'
                },
                capacity: {
                    name: 'Pojemność',
                    value: '',
                    place: 32,
                    type: 'Specs'
                },
                cameraFront: {
                    name: 'Aparat (Przód)',
                    value: '',
                    place: 33,
                    type: 'Specs'
                },
                cameraBack: {
                    name: 'Aparat (Tył)',
                    value: '',
                    place: 34,
                    type: 'Specs'
                },
                powerSupply: {
                    name: 'Ładowarka',
                    value: '',
                    place: 35,
                    type: 'Specs'
                },

                mobileScreen: {
                    name: 'Rodzaj wyświetlacza',
                    value: '',
                    place: 36,
                    type: 'Specs'
                },
                itemStandard: {
                    name: 'Standard',
                    value: '',
                    place: 37,
                    type: 'Specs'
                },
                capacityOfBattery: {
                    name: 'Pojemność akumulatora',
                    value: '',
                    place: 38,
                    type: 'Specs'
                },
                slotOfMemoryCard: {
                    name: 'Slot karty pamięci',
                    value: '',
                    place: 39,
                    type: 'Specs'
                },
                navigation: {
                    name: 'Nawigacja',
                    value: '',
                    place: 40,
                    type: 'Specs'
                },
                sensors: {
                    name: 'Czujniki',
                    value: '',
                    place: 41,
                    type: 'Specs'
                },
                functions: {
                    name: 'Funkcje',
                    value: '',
                    place: 42,
                    type: 'Specs'
                },
                dataTransmision: {
                    name: 'Transmisja danych',
                    value: '',
                    place: 43,
                    type: 'Specs'
                },
                brightness: {
                    name: 'Jasność',
                    value: '',
                    place: 1,
                    type: 'More'
                },
                contrast: {
                    name: 'Kontrast',
                    value: '',
                    place: 2,
                    type: 'More'
                },
                viewAngle: {
                    name: 'Kąt widzenia',
                    value: '',
                    place: 3,
                    type: 'More'
                },
                withFoot: {
                    name: 'Stopa w komplecie',
                    value: '',
                    place: 4,
                    type: 'More'
                },
                pivot: {
                    name: 'Pivot',
                    value: '',
                    place: 5,
                    type: 'More'
                },
                heightAdjustment: {
                    name: 'Regulacja wysokości',
                    value: '',
                    place: 6,
                    type: 'More'
                },
                angleAdjustment: {
                    name: 'Regulacja kąta nachylenia',
                    value: '',
                    place: 7,
                    type: 'More'
                },
                hasSpeaker: {
                    name: 'Wbudowany głośnik',
                    value: '',
                    place: 8,
                    type: 'More'
                },
                graphicsType: {
                    name: 'Rodzaj karty graficznej',
                    value: '',
                    place: 9,
                    type: 'More'
                },
                graphics: {
                    name: 'Model karty graficznej',
                    value: '',
                    place: 10,
                    type: 'More'
                },
                insideConnections: {
                    name: 'Złącza wewnętrzne',
                    value: '',
                    place: 11,
                    type: 'More'
                },
                allConnectionsOutside: {
                    name: 'Złącza zewnętrzne',
                    value: '',
                    place: 12,
                    type: 'More'
                },
                frontConnections: {
                    name: 'Złącza z przodu',
                    value: '',
                    place: 13,
                    type: 'More'
                },
                backConnections: {
                    name: 'Złącza z tyłu',
                    value: '',
                    place: 14,
                    type: 'More'
                },
                sideConnections: {
                    name: 'Złącza z boku',
                    value: '',
                    place: 15,
                    type: 'More'
                },
                dvd: {
                    name: 'Napęd',
                    value: '',
                    place: 16 ,
                    type: 'More'
                },
                camera: {
                    name: 'Kamera',
                    value: '',
                    place: 17,
                    type: 'More'
                },
                networkStandard: {
                    name: 'Karta Sieciowa',
                    value: '',
                    place: 18,
                    type: 'More'
                },
                networkWireless: {
                    name: 'Komunikacja',
                    value: '',
                    place: 19,
                    type: 'More'
                },
                battery: {
                    name: 'Bateria',
                    value: '',
                    place: 20,
                    type: 'More'
                },
                keyboard: {
                    name: 'Klawiatura',
                    value: '',
                    place: 21,
                    type: 'More'
                },
                sound: {
                    name: 'Karta dźwiękowa',
                    value: '',
                    place: 22,
                    type: 'More'
                },
                additionalInfo: {
                    name: "Informacje dodatkowe",
                    value: "",
                    place: 23,
                    type: 'More'
                },
                inPack: {
                    name: "W zestawie",
                    value: "",
                    place: 24,
                    type: 'More'
                },
                itemHeight: {
                    name: 'Wysokość (cm)',
                    value: '',
                    place: 1,
                    type: 'Dimensions'
                },
                itemWidth: {
                    name: 'Szerokość (cm)',
                    value: '',
                    place: 2,
                    type: 'Dimensions'
                },
                itemLength: {
                    name: 'Długość (cm)',
                    value: '',
                    place: 3,
                    type: 'Dimensions'
                },
                itemDepth: {
                    name: 'Głębokość (cm)',
                    value: '',
                    place: 4,
                    type: 'Dimensions'
                },
                weight: {
                    name: 'Waga',
                    value: 5,
                    place: 5,
                    type: 'Dimensions'
                },
            },
            created: '',
            edited: '',
            addons: [], // Product Addons and prices for addons
        },
        // For Variants and Addons Creation
        selectedIndexOfAddon: 99, // Index of Addon for adding function
        variantName: "", // New Variant Name
        variantPrice: 0,  // New Variant Price
        newAddonName: "", // Name of addon that is creating
        // Stepper Control
        currentStep: "first",
        // Adding item
        askForAdd: false,
        loading: false,
        loadingInfo: "",
        //ERRORS
        errors: {
            nameError: false,
            qtyError: false,
            priceError: false,
            imageError: false,
            weightError: false,
        },
        showSnackbar: false,
        snackbarMsg: "Błąd podczas uzupełniania formularza.",
        askForEdit: false,
        rendered: false,
        //Image Gallery on edit
        allImages: [],
        selectedToDelete: [],
        selectedImage: {
            url: "",
            indx: 0,
            keyword: ""
        },
        showEditModeUpload: true,
        clearer: "",
        askForDelete: false,
        // Category Mechanics
        availableCategories: [],
        pickedSubcategory: {},
        //Adding Addons
        addingVariantLoading: false,
        showDialogAddons: false,
        addonsFromDb: [],
        beforeEditPrice: null,
        beforeEditProduct: {},
    }
  },
  components: {
      loader
  },
  props: ['item', 'attribs', 'userInfo'],
  created()
  {
      this.loadItemData();
  },
  methods: {
        checkSku(){
            if(this.product.sku.includes("+")){
            console.log(this.product.sku)
            this.product.sku = this.product.sku.replace("+","");
            console.log(this.product.sku)
            }
        },
        changePrices(){
            if(this.product.promotion === true){
                this.product.priceBeforePromotion = parseFloat(this.product.basePrice)
            }else{
                this.product.basePrice = parseFloat(this.product.priceBeforePromotion)
            }
        },
        clearEverything()
        {
            this.currentStep = 'first';
            this.selectedImage = {
            url: "",
            indx: 0,
            keyword: ""
            };
        },
        loadItemData()
        {
            this.clearEverything();
            db.collection("Categories").where('showInDashboard',"==", true).get().then((snapshot)=>
            {
                for(let index in snapshot.docs)
                {
                    this.availableCategories.push(snapshot.docs[index].data());
                }
                this.product.category = this.availableCategories[0].name;
                this.pickedSubcategory = this.availableCategories[0].subcategories;
                this.product.subcategory = this.availableCategories[0].subcategories[0];
                // Load for edit
                if(this.item !== undefined)
                {
                    this.rendered = false;
                    this.allImages = [];
                    db.collection("Products").doc(this.item).get().then((doc)=>
                    {
                        let item = doc.data();
                        if(item.inpost === undefined)
                        {
                            item.inpost = {
                                allowed: false
                            }
                        }
                        this.product = item;
                        this.beforeEditProduct = JSON.parse(JSON.stringify(item));
                        this.beforeEditPrice = item.basePrice
                        setTimeout(()=>
                        {
                            this.rendered = true;
                        },200)
                        
                        
                        for(let index in this.availableCategories)
                        {
                            if(this.availableCategories[index].name === this.product.category)
                            {
                                this.pickedSubcategory = this.availableCategories[index].subcategories;
                            }
                        }
                        this.allImages.push(this.product.mainImage);
                        for(let pictures in this.product.imageGallery)
                        {
                            this.allImages.push(this.product.imageGallery[pictures]);
                        }
                        this.allImages.sort((a, b) => (a.indx > b.indx) ? 1 : -1)
                        for(let i=0; i<this.product.addonsIds.length; i++)
                        {
                            let addonId = this.product.addonsIds[i].id;
                            db.collection("SavedProductAddons").doc(addonId).get().then((snapshot)=>
                            {
                                if(snapshot.exists)
                                {
                                    let data = snapshot.data();
                                    this.preparedAddons.push(data);
                                }
                            })
                        }
                    }).catch((error)=>
                    {
                        console.log(error);
                    })
                }
                else
                {
                    setTimeout(()=>
                    {
                        this.rendered = true;
                    },200)
                }
            }).catch((error)=>
            {
                console.log(`Could not load categories: ${error}`);
            })
        },
        inputCrudAddonEdited(type,value,addon)
        {
            if(type === 'number' && value.price>0 && value.name)
            {
                value.price = parseFloat(value.price);
                db.collection("SavedProductAddons").doc(addon.id).update(addon).then(()=>
                {
                    this.snackbarMsg = "Zapisano w opcji dodatku."
                    this.showSnackbar = true;
                }).catch((error)=>
                {
                    this.snackbarMsg = "Nastąpił błąd podczas zapisywania dodatku. Sprawdź konsolę."
                    this.showSnackbar = true;
                    console.log(error);
                })
            }
            if(type === 'percent' && value.percantage>0 && value.name)
            {
                value.percantage = parseInt(value.percantage);
                db.collection("SavedProductAddons").doc(addon.id).update(addon).then(()=>
                {
                    this.snackbarMsg = "Zapisano w opcji dodatku."
                    this.showSnackbar = true;
                }).catch((error)=>
                {
                    this.snackbarMsg = "Nastąpił błąd podczas zapisywania dodatku. Sprawdź konsolę."
                    this.showSnackbar = true;
                    console.log(error);
                })
            }
        },
        getLink(evt,indx)
        {
            if(evt.length>0)
            {
                this.uploadSectionLink(evt[0],indx);
            }
        },
        addDescSection()
        {
            this.product.descSections.push(
                {
                    header: "",
                    text: "",
                    url: "",
                    keyword: ""
                }
            )
            this.snackbarMsg = "Sekcja opisowa dodana.";
            this.showSnackbar = true;
        },
        addToAvailableOptions(variant,addon)
        {
            variant.availableIn.push(this.product.uniqueID);
            db.collection("SavedProductAddons").doc(addon.id).update(addon).catch((error)=>
            {
                console.log(error);
            })
        },
        removeFromAvailableOptions(variant,addon)
        {
            for(let i=0;i<variant.availableIn.length; i++)
            {
                if(variant.availableIn[i] === this.product.uniqueID){
                    variant.availableIn.splice(i,1);
                }
            }

            db.collection("SavedProductAddons").doc(addon.id).update(addon).catch((error)=>
            {
                console.log(error);
            })
        },
        optionArrayMove(arr, fromIndex, toIndex,addon) {
        let element = arr[fromIndex];
        arr.splice(fromIndex, 1);
        arr.splice(toIndex, 0, element);
        db.collection("SavedProductAddons").doc(addon.id).update(addon).catch((error)=>
        {
            console.log(error);
        })
        for(let i=0; i<this.preparedAddons.length;i++)
        {
            if(this.preparedAddons[i].id === addon.id)
            {
                this.preparedAddons[i] = addon;
            }
        }
        },
        updateAddonOption(addon,inputObject)
        {
            let newOption = {
                name: inputObject.name,
                price: parseFloat(inputObject.price),
                percantage: parseInt(inputObject.percantage),
                unavailableIn: [],
                availableIn: []
            }
            if(addon.percantagePrice)
            {
                newOption.price = 0;
            }
            else
            {
                newOption.percantage = 0;
            }

            addon.options.push(newOption);
            db.collection("SavedProductAddons").doc(addon.id).update(addon).catch((error)=>
            {
                console.log(error);
            });
            for(let i=0; i<this.preparedAddons.length;i++)
            {
                if(this.preparedAddons[i].id === addon.id)
                {
                    this.preparedAddons[i] = addon;
                }
            }
            inputObject.name = "";
            inputObject.price = 0;
            inputObject.percantage = 0;
        },
        newAddonsItemsValue()
        {
            this.newAddonInputsVModelArray = [];
            for(let i = 0; i<this.addonsFromDb.length;i++)
            {
                this.newAddonInputsVModelArray.push({
                    name: "",
                    price: 0,
                    percantage: 0
                })
            }
        },
        deleteOptionFromAddonDb(addon,opIndx)
        {
            addon.options.splice(opIndx,1);
            db.collection("SavedProductAddons").doc(addon.id).update(addon).catch((error)=>
            {
                console.log(error);
            });
            this.snackbarMsg = "Usunięto opcję z dodatku.";
            this.showSnackbar = true;
            
        },
        saveAddonToDb()
        {
            db.collection("SavedProductAddons").add(this.newAddonScheme).then((snapshot)=>
            {
                this.newAddonScheme.id = snapshot.id;
                db.collection("SavedProductAddons").doc(snapshot.id).update(this.newAddonScheme).then(()=>
                {
                    this.newAddonScheme.name = "";
                    this.newAddonScheme.options = [];
                    this.snackbarMsg = "Zapisano dodatek w bazie danych. Możesz go teraz wczytać."
                    this.showDialogAddons = false;
                    this.showSnackbar = true;
                }).catch((error)=>{
                    console.log(error);
                })
            }).catch((error)=>
            {
                console.log(error);
            })
        },
        addToAddonScheme(type)
        {   
            let scheme = {}
            if(type==="normal")
            {
                scheme = {
                    name: this.addonInputHolder.name,
                    price: parseFloat(this.addonInputHolder.price),
                    percantage: 0,
                    optionId: this.createFileName(64),
                    unavailableIn: [],
                    availableIn: []
                }
            }
            else
            {
                scheme = {
                    name: this.addonInputHolder.name,
                    price: 0,
                    optionId: this.createFileName(64),
                    percantage: parseInt(this.addonInputHolder.percantage),
                    unavailableIn: [],
                    availableIn: []
                }
            }
            this.newAddonScheme.options.push(scheme);
            this.addonInputHolder.name = "",
            this.addonInputHolder.price = 0;
            this.addonInputHolder.percantage = 0;
        },
        goToMainProduct()
        {
            db.collection("Products").doc(this.product.motherProductID).get().then((snapshot)=>
            {
                if(snapshot.exists)
                {
                    this.product = snapshot.data()
                    this.currentStep = "first";
                    this.snackbarMsg = "Załadowano. Zmieniasz teraz przedmiot nadrzędny."
                    this.showSnackbar= true;
                }
                else
                {
                    this.product.motherProductID = "";
                    this.product.isDuplicate = false;
                    this.snackbarMsg = "Nie wykryto produktu nadrzędnego. Mógł on zostać wcześniej usunięty. Zapisz, aby produkt stał się nadrzędny."
                    this.showSnackbar= true;
                }
            })
        },
        loadProductFromVariant(indx)
        {
            this.loading = true;
            this.loadingInfo = "Wczytywanie produktu.";
            this.rendered = false;
            if(this.variantsData.length>0)
            {
                
                
                this.product = this.variantsData[indx];
                this.variantsData = [];
                this.currentStep = 'first';
                setTimeout(()=>
                {
                    this.rendered = true;
                },200)
                this.loading = false;
                this.snackbarMsg = "Jesteś w trakcie edycji wariantu.";
                this.showSnackbar = true;
                
            }
            else
            {
                this.loading = false;
                this.loadingInfo = "Wczytywanie produktu.";
                setTimeout(()=>
                {
                    this.rendered = true;
                },200)
            }

        },
        async downloadVariantsInfo()
        {
            this.loadingInfo = "Pobieranie informacji o wariantach.";
            this.loading = true;
            this.lockVariantDownload = true;
            this.variantData = [];
            for(let i=0; i<this.product.variantsID.length; i++)
            {
                let item = await db.collection("Products").doc(this.product.variantsID[i]).get()
                if(item.exists)
                {
                    this.variantsData.push(item.data());
                }
            }
            this.loading = false;
            if(this.variantsData.length>0)
            {
                this.snackbarMsg = "Pobrano wszystkie istniejące dane o wariantach."
                this.showSnackbar = true;
            }
            else
            {
                this.snackbarMsg = "Nie znaleziono żadnych istniejących wariantów."
                this.showSnackbar = true;
                this.product.variantsID = [];
            }

        },
        moveImageRight()
        {
            console.log('work')
            this.selectedImage.indx++;
            this.allImages.sort((a, b) => (a.indx > b.indx) ? 1 : -1)
            for(let i=0; i<this.allImages.length; i++)
            {
                this.allImages[i].indx = i;
            }
            this.allImages.sort((a, b) => (a.indx > b.indx) ? 1 : -1)
            //this.allImages.sort((a, b) => (a.indx > b.indx) ? 1 : -1)
            //this.allImages.sort((a, b) => (a.indx > b.indx) ? 1 : -1)
            //this.allImages.sort((a, b) => (a.indx > b.indx) ? 1 : -1)
            //this.allImages = this.allImages.sort((a, b) => (a.indx > b.indx) ? 1 : (a.indx < b.indx) ? -1 : 0)

            
            
        },
        moveImageLeft()
        {
            this.selectedImage.indx--;
            this.allImages.sort((a, b) => (a.indx > b.indx) ? 1 : -1)
            for(let i=0; i<this.allImages.length; i++)
            {
                this.allImages[i].indx = i;
            }
            this.allImages.sort((a, b) => (a.indx > b.indx) ? 1 : -1)

        },
        updateBaselinker()
        {
            this.loadingInfo = "Aktualizacja produktu w magazynie Baselinkera";
            let imgShortnrPromises = [];
            imgShortnrPromises.push(this.shortenUrl({link: this.product.mainImage.url}));
            for(let i=0; i<this.product.imageGallery.length;i++)
            {
                imgShortnrPromises.push(this.shortenUrl({link: this.product.imageGallery[i].url}))
            }
            this.loadingInfo = `Skracanie linków obrazków poprzez Bit.ly`;
            Promise.all(imgShortnrPromises).then((result)=>{
                let shortenedLinks = [];
                for(let y=0; y<result.length;y++)
                {
                    let currentResult = result[y];
                    shortenedLinks.push('url:'+currentResult.data.link);
                }
                this.product.baselinkerPreparedImages = shortenedLinks;
                this.loadingInfo = `Aktualizowanie produktu..`;
                axios.post('https://europe-central2-kompreshop.cloudfunctions.net/webApi/updateProductOnBaselinker', this.product)
                .then((res)=>
                {
                    this.showSnackbar = true;
                    this.snackbarMsg = "Zaktualizowano produkt w Baselinkerze."
                    this.loading = false;
                }).catch((error)=>
                {
                    console.log(error);
                    alert("Nie udało się zaktualizować produktu Baselinkera. Sprawdź opis swojego produktu. Niektóre znaki mogą być problematyczne dla Baselinkera.");
                    this.loading = false;
                })

            }).catch((error)=>
            {
                console.log(error);
                alert("Nie udało się skrócić linków poprzez Bit.ly");
            })
            
        },
        updateSellasist()
        {
            this.loadingInfo = "Aktualizacja produktu w magazynie Sellasist";
            let imgShortnrPromises = [];
            imgShortnrPromises.push(this.shortenUrl({link: this.product.mainImage.url}));
            for(let i=0; i<this.product.imageGallery.length;i++)
            {
                imgShortnrPromises.push(this.shortenUrl({link: this.product.imageGallery[i].url}))
            }
            this.loadingInfo = `Skracanie linków obrazków poprzez Bit.ly`;
            Promise.all(imgShortnrPromises).then((result)=>{
                let shortenedLinks = [];
                for(let y=0; y<result.length;y++)
                {
                    let currentResult = result[y];
                    shortenedLinks.push(currentResult.data.link);
                }
                this.product.sellasistPreparedImages = shortenedLinks;
                this.loadingInfo = `Aktualizowanie produktu..`;
                axios.post('https://europe-central2-kompreshop.cloudfunctions.net/webApi/updateProductOnSellasist', this.product)
                // axios.post('http://127.0.0.1:5001/kompreshop/europe-central2/webApi/updateProductOnSellasist', this.product)
                .then((res)=>
                {
                    this.showSnackbar = true;
                    this.snackbarMsg = "Zaktualizowano produkt w Sellasist."
                    this.loading = false;
                }).catch((error)=>
                {
                    console.log(error);
                    alert("Nie udało się zaktualizować produktu w Sellasist. Sprawdź opis swojego produktu. Niektóre znaki mogą być problematyczne dla systemu Sellasist.");
                    this.loading = false;
                })

            }).catch((error)=>
            {
                console.log(error);
                alert("Nie udało się skrócić linków poprzez Bit.ly");
            })
            
        },
        askForDuplicate()
        {
            this.duplicateWindow = true;
            this.loading = true;
        },
        askForSellasistUpdate()
        {
            this.loading = true;
            this.sellasistUpdateAsk = true;
        },
        askForBaselinkerUpdate()
        {
            this.loading = true;
            this.baselinkerUpdateAsk = true;
        },
        askForDuplicateAsMain()
        {
            this.loading = true;
            this.askForCreatingMain = true;
        },
        duplicateAsMain()
        {
            this.loadingInfo = `Rozpoczynanie dodawania duplikatu produktu.`;
            let newCopy = JSON.parse(JSON.stringify(this.product));
            newCopy.baselinkerProductID = "";
            newCopy.sellasistProductID = "";
            newCopy.uniqueID = "";
            newCopy.isDuplicate = false;
            newCopy.linkToItem = "";
            newCopy.motherProductID = "";
            newCopy.variantsID = [];
            newCopy.baselinkerPreparedImages = [];
            this.preparedAddons = [];
            let imgShortnrPromises = [];
            imgShortnrPromises.push(this.shortenUrl({link: newCopy.mainImage.url}));
            for(let i=0; i<newCopy.imageGallery.length;i++)
            {
                imgShortnrPromises.push(this.shortenUrl({link: newCopy.imageGallery[i].url}))
            }
            this.loadingInfo = `Skracanie linków obrazków poprzez Bit.ly`;
            Promise.all(imgShortnrPromises).then((result)=>
            {
                let shortenedLinks = [];
                for(let y=0; y<result.length;y++)
                {
                    let currentResult = result[y];
                    // shortenedLinks.push('url:'+currentResult.data.link);
                    shortenedLinks.push(currentResult.data.link);
                }
                // newCopy.baselinkerPreparedImages = shortenedLinks;
                newCopy.sellasistPreparedImages = shortenedLinks;
                this.loadingInfo = `Wkładanie produktu do bazy danych Firebase.`;
                db.collection("Products").add(newCopy).then((snapshot)=>
                {
                    let uid = snapshot.id;
                    let link = "https://kompreshop.web.app/produkt/"+uid;
                    newCopy.uniqueID = uid;
                    newCopy.linkToItem = link;
                    db.collection('Products').doc(uid).update({
                        uniqueID: uid,
                        linkToItem: link
                    }).then(()=>
                    {
                        this.loadingInfo = `Edytowanie tablicy ID kategorii.`;
                        db.collection('Categories').doc(newCopy.category).update({
                            productsID: firebase.firestore.FieldValue.arrayUnion(uid)
                        }).then(()=>
                        {
                            this.loadingInfo = `Dodawanie produktu do systemu Sellasist.`;
                            axios.post('https://europe-central2-kompreshop.cloudfunctions.net/webApi/addProductToSellasist', newCopy).then((res)=>
                            {
                                this.loadingInfo = `Przypisywanie ID produktu Sellasist do produktu sklepowego.`;
                                this.loading = false;
                                if(res.data.product_id != null)
                                {
                                    db.collection('Products').doc(newCopy.uniqueID).update({
                                        "sellasistProductID": res.data.product_id,
                                        // "sku": res.data.product_id
                                    }).then(()=>
                                    {
                                        this.loadingInfo = `Kończenie procesu dodawania produktu.`;
                                        db.collection('Products').doc(this.product.uniqueID).update({
                                            variantsID: firebase.firestore.FieldValue.arrayUnion(uid)
                                        }).then(()=>
                                        {
                                            //this.$emit('showSuccess');
                                            this.item = uid;
                                            this.snackbarMsg = "Załadowano zduplikowany produkt."
                                            this.showSnackbar = true;
                                            this.loadItemData();
                                        }).catch((error)=>
                                        {
                                            console.log(error);
                                            alert("Nie przypisano ID dziecka do tablicy rodzica. Więcej w konsoli.")
                                        })
                                        
                                    }).catch((err)=>
                                    {
                                        console.log(err);
                                        alert("Nie przypisano ID sellasist do produktu sklepowego. Wiecej w konsoli.");
                                    })
                                }
                                else
                                {
                                    alert("Dodawanie do sellasist nie zakończyło się sukcesem. Sellasist zwrócił inny status. Więcej w konsoli.");
                                    console.log(res.data);
                                }
                            }).catch((err)=>
                            {
                                console.log(err);
                                alert("Wystąpił błąd podczas dodawania produktu do Sellasist. Więcej w konsoli.")
                            })
                        }).catch((error)=>
                        {
                            console.log(error);
                            alert("Nie udało się dopisać ID do kategorii. Więcej w konsoli.")
                        })
                    }).catch((error)=>
                    {
                        console.log(error);
                        alert("Nie udało się podmienić ID po wgraniu do Firebase. Więcej w konsoli.");
                    })

                }).catch((error)=>
                {
                    console.log(error);
                    alert("Nie udało się zapisać produktu do bazy Firebase. Więcej w konsoli.")
                })

                
            }).catch((error)=>
            {
                alert("Nie udało się skrócić URL obrazków. Więcej w konsoli.");
                console.log(error);
            })
        },
        duplicate()
        {
            this.loadingInfo = `Rozpoczynanie dodawania duplikatu produktu.`;
            let newCopy = JSON.parse(JSON.stringify(this.product));
            newCopy.baselinkerProductID = "";
            newCopy.sellasistProductID = "";
            newCopy.uniqueID = "";
            newCopy.isDuplicate = true;
            newCopy.motherProductID = this.product.uniqueID;
            newCopy.baselinkerPreparedImages = [];
            newCopy.sellasistPreparedImages = [];
            let imgShortnrPromises = [];
            imgShortnrPromises.push(this.shortenUrl({link: newCopy.mainImage.url}));
            for(let i=0; i<newCopy.imageGallery.length;i++)
            {
                imgShortnrPromises.push(this.shortenUrl({link: newCopy.imageGallery[i].url}))
            }
            this.loadingInfo = `Skracanie linków obrazków poprzez Bit.ly`;
            Promise.all(imgShortnrPromises).then((result)=>
            {
                let shortenedLinks = [];
                for(let y=0; y<result.length;y++)
                {
                    let currentResult = result[y];
                    // shortenedLinks.push('url:'+currentResult.data.link);
                    shortenedLinks.push(currentResult.data.link);
                }
                // newCopy.baselinkerPreparedImages = shortenedLinks;
                newCopy.sellasistPreparedImages = shortenedLinks;
                this.loadingInfo = `Wkładanie produktu do bazy danych Firebase.`;
                db.collection("Products").add(newCopy).then((snapshot)=>
                {
                    let uid = snapshot.id;
                    let link = "https://kompreshop.web.app/produkt/"+uid;
                    newCopy.uniqueID = uid;
                    newCopy.linkToItem = link;
                    db.collection('Products').doc(uid).update({
                        uniqueID: uid,
                        linkToItem: link
                    }).then(()=>
                    {
                        this.loadingInfo = `Edytowanie tablicy ID kategorii.`;
                        db.collection('Categories').doc(newCopy.category).update({
                            productsID: firebase.firestore.FieldValue.arrayUnion(uid)
                        }).then(()=>
                        {
                            this.loadingInfo = `Dodawanie produktu do systemu Sellasist.`;
                            axios.post('https://europe-central2-kompreshop.cloudfunctions.net/webApi/addProductToSellasist', newCopy).then((res)=>
                            {
                                this.loadingInfo = `Przypisywanie ID produktu Sellasist do produktu sklepowego.`;
                                this.loading = false;
                                if(res.data.product_id != null)
                                {
                                    db.collection('Products').doc(newCopy.uniqueID).update({
                                        "sellasistProductID": res.data.product_id,
                                        // "sku": res.data.product_id
                                    }).then(()=>
                                    {
                                        this.loadingInfo = `Kończenie procesu dodawania produktu.`;
                                        db.collection('Products').doc(this.product.uniqueID).update({
                                            variantsID: firebase.firestore.FieldValue.arrayUnion(uid)
                                        }).then(()=>
                                        {
                                            this.$emit('showSuccess');
                                        }).catch((error)=>
                                        {
                                            console.log(error);
                                            alert("Nie przypisano ID dziecka do tablicy rodzica. Więcej w konsoli.")
                                        })
                                        
                                    }).catch((err)=>
                                    {
                                        console.log(err);
                                        alert("Nie przypisano ID Sellasist do produktu sklepowego. Wiecej w konsoli.");
                                    })
                                }
                                else
                                {
                                    alert("Dodawanie do Sellasist nie zakończyło się sukcesem. Sellasist zwrócił inny status. Więcej w konsoli. Sprawdź opis swojego produktu. Niektóre znaki mogą być problematyczne dla Sellasist.");
                                    console.log(res.data);
                                }
                            }).catch((err)=>
                            {
                                console.log(err);
                                alert("Wystąpił błąd podczas dodawania produktu do Sellasist. Więcej w konsoli.")
                            })

                        }).catch((error)=>
                        {
                            console.log(error);
                            alert("Nie udało się dopisać ID do kategorii. Więcej w konsoli.")
                        })
                    }).catch((error)=>
                    {
                        console.log(error);
                        alert("Nie udało się podmienić ID po wgraniu do Firebase. Więcej w konsoli.");
                    })

                }).catch((error)=>
                {
                    console.log(error);
                    alert("Nie udało się zapisać produktu do bazy Firebase. Więcej w konsoli.")
                })

                
            }).catch((error)=>
            {
                alert("Nie udało się skrócić URL obrazków. Więcej w konsoli.");
                console.log(error);
            })
        },
        changeItemCondition(value)
        {
            if(value === "A")
            {
                this.product.specs.itemCondition.value = '"A" poleasingowy, przetestowany';
            }
            if(value === "A-")
            {
                this.product.specs.itemCondition.value = '"A-" poleasingowy, przetestowany';
            }
            if(value === "B")
            {
                this.product.specs.itemCondition.value = '"B" poleasingowy, przetestowany';
            }
            if(value === "C")
            {
                this.product.specs.itemCondition.value = '"C" poleasingowy, przetestowany';
            }
            if(value === "Powystawowy")
            {
                this.product.specs.itemCondition.value = 'Powystawowy / Leżak magazynowy';
            }
            if(value === "Nowy")
            {
                this.product.specs.itemCondition.value = 'Nowy';
            }
        },
        changeItemClass(value)
        {
            if(value === '"A" poleasingowy, przetestowany')
            {
                this.product.qualityClass = "A"
            }
            if(value === '"A-" poleasingowy, przetestowany')
            {
                this.product.qualityClass = "A-"
            }
            if(value === '"B" poleasingowy, przetestowany')
            {
                this.product.qualityClass = "B"
            }
            if(value === '"C" poleasingowy, przetestowany')
            {
                this.product.qualityClass = "C"
            }
            if(value === 'Powystawowy / Leżak magazynowy')
            {
                this.product.qualityClass = "Powystawowy"
            }
            if(value === "Nowy")
            {
                this.product.qualityClass = "Nowy"
            }

        },
        shortenUrl(linkData) {
        return axios.post('https://europe-central2-kompreshop.cloudfunctions.net/webApi/shortenLink', linkData);
        },
        /*
        getBase64(file) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            let currentBaseCode = reader.result.split(',')[1];
            this.product.baselinkerPreparedBase64.push("data:"+currentBaseCode);
        };
        reader.onerror = (error)=> {
            console.log('Error: ', error);
        };
        },
        */
        clearSpecs()
        {
            this.product.specs.processorType.value = "";
            this.product.specs.screenCondition.value = "";
            this.product.specs.casingCondition.value = "";
            this.product.specs.processor.value = "";
            this.product.specs.mobileProductProcessor.value = "";
            this.product.specs.cpuSpeed.value = "";
            this.product.specs.coreNum.value = "";
            this.product.specs.socket.value = "";
            this.product.specs.motherboard.value = "";
            this.product.specs.ram.value = "";
            this.product.specs.ramType.value = "";
            this.product.specs.hardDrive.value = "";
            this.product.specs.secondHardDrive.value = "";
            this.product.specs.typeOfHardrive.value = "";
            this.product.specs.os.value = "";
            this.product.specs.installedOS.value = "";
            this.product.specs.coaType.value = "";
            this.product.specs.displayTouchable.value = "";
            this.product.specs.displayResolution.value = "";
            this.product.specs.displaySize.value = "";
            this.product.specs.displayType.value = "";
            this.product.specs.mobileScreen.value = "";
            this.product.specs.backlight.value = "";
            this.product.specs.screenType.value = "";
            this.product.specs.casing.value = "";
            this.product.specs.brightness.value = "";
            this.product.specs.contrast.value = "";
            this.product.specs.viewAngle.value = "";
            this.product.specs.withFoot.value = "";
            this.product.specs.pivot.value = "";
            this.product.specs.heightAdjustment.value = "";
            this.product.specs.angleAdjustment.value = "";
            this.product.specs.hasSpeaker.value = "";
            this.product.specs.graphicsType.value = "";
            this.product.specs.graphics.value = "";
            this.product.specs.insideConnections.value = "";
            this.product.specs.frontConnections.value = "";
            this.product.specs.backConnections.value = "";
            this.product.specs.sideConnections.value = "";
            this.product.specs.dvd.value = "";
            this.product.specs.camera.value = "";
            this.product.specs.networkStandard.value = "";
            this.product.specs.networkWireless.value = "";
            this.product.specs.battery.value = "";
            this.product.specs.keyboard.value = "";
            this.product.specs.sound.value = "";
        },
        goToAuction()
        {
            window.open(`https://kompreshop.web.app/produkt/${this.product.uniqueID}`, '_blank');
        },
        toggleAddonLoadTab()
        {
            this.addonsFromDb = [];
            if(this.showDialogAddons)
            {
                this.addonsFromDb = [];
                this.showDialogAddons = false;
                this.newAddonsItemsValue();
                
            }
            else
            {
                db.collection('SavedProductAddons').get().then((snapshot)=>
                {
                    snapshot.docs.forEach((doc)=>
                    {
                        let item = doc.data();
                        this.addonsFromDb.push(item);
                        
                    })
                    this.newAddonsItemsValue();
                    this.showDialogAddons = true;
                })

            }
        },
        deleteAddonFromDatabase(addon)
        {

            this.addingVariantLoading = true;
            db.collection("SavedProductAddons").doc(addon.id).delete().then(()=>
            {
                for(let i=0; i<this.product.addonsIds.length;i++)
                {
                    if(this.product.addonsIds[i].id === addon.id)
                    {
                        this.product.addonsIds.splice(i,1);
                    }
                }

                for(let i=0; i<this.preparedAddons.length;i++)
                {
                    if(this.preparedAddons[i].id === addon.id)
                    {
                        this.preparedAddons.splice(i,1);
                    }
                }
                addon.id = "";
                this.addingVariantLoading = false;
                this.showDialogAddons = false;
                this.snackbarMsg = "Usunięto dodatek z bazy danych."
                this.showSnackbar = true;
                
            }).catch((error)=>{
                console.log(error);
            })
        },
        updateAddonAtDatabase(addon)
        {
            this.addingVariantLoading = true;
            db.collection("SavedProductAddons").doc(addon.id).update(addon).then(()=>
            {
                this.addingVariantLoading = false;
                this.snackbarMsg = "Nadpisano dodatek w bazie danych."
                this.showSnackbar = true;
            }).catch((error)=>{
                console.log(error);
            })

        },
        changeCategory(pickCat)
        {
            for(let index in this.availableCategories)
            {
                if(this.availableCategories[index].name === pickCat)
                {
                    this.pickedSubcategory = this.availableCategories[index].subcategories;
                    if(this.availableCategories[index].subcategories.length>0)
                    {
                        this.product.subcategory = this.availableCategories[index].subcategories[0];
                    }
                    else
                    {
                        this.product.subcategory = "";
                    }
                    
                }
            }
            this.clearSpecs();
        },
        deleteImage(url)
        {
            let pictureRef = storage.refFromURL(url);
            pictureRef.delete()
            .then(() => {
                console.log('Image deleted.');
            })
            .catch((err) => {
                console.log(err);
            });
        },
        onConfirmDeleteItem()
        {

        const backupId = db.collection('Backups').doc('Products').collection('removedProducts').doc()
        const logId = db.collection('Logs').doc()
          db.collection('Logs').doc(logId.id).set({
            message: `Usunięcie produktu ${this.product.name}, przez ${this.userInfo.name} ${this.userInfo.surname}, Backup znajdziesz tutaj: `,
            user: {
              mail: this.userInfo.mail,
              name: this.userInfo.name,
              surname: this.userInfo.surname,
            },
            backupId: backupId.id,
            product: this.product,
            createdAt: new Date(),
            uniqueID: logId.id,
            baselinkerID: this.product.baselinkerProductID,
            sellasistID: this.product.sellasistProductID
          })
          db.collection('Backups').doc('Products').collection('removedProducts').doc(backupId.id).set({
            product: this.product,
            uniqueID: this.product.uniqueID,
            createdAt: new Date(),
          })          
        
            // Delete id from category
            db.collection("Categories").doc(this.product.category).update({
                productsID: firebase.firestore.FieldValue.arrayRemove(this.product.uniqueID)
            });
            // Delete images
            /*
            if(this.product.isDuplicate === false && this.product.variantsID.length === 0)
            {
                for(let i=0;i < this.allImages.length; i++)
                {
                    this.deleteImage(this.allImages[i].url);
                }
            }
            */
            // Delete product
            db.collection("Products").doc(this.product.uniqueID).delete().then(() => {
                this.goBackToList()
            }).catch((error) => {
                console.error("Error removing product: ", error);
            });
        },
        prepareDelete()
        {
            this.askForDelete = true;
        },
        uploadToAllImages()
        {
            if(this.allImages.length < 16)
            {
                const galleryImageRefList = this.$refs.editImageUploader.$refs.inputFile.files;
                for(let i = 0; i < galleryImageRefList.length; i++)
                {
                    this.showEditModeUpload = false;
                    this.uploadImage(galleryImageRefList[i],"edit");
                }
                this.clearer = "";
            }
            else
            {
                alert('Maksymalna ilość dodanych zdjęć do produktu.')
            }
        },
        //Select from gallery
        selectImageFromGallery(indexX,indexY)
        {
            let itemIndex = 1;
            if(indexY-1 > 0)
            {
                let row = (indexY-1)*6;
                itemIndex = row+indexX;
            }
            else
            {
                itemIndex = indexX;
            }
            this.selectedImage = this.allImages[itemIndex];
        },
        // Make it main image on edit
        makeItMainImage()
        {
            for(let i=0; i<this.allImages.length; i++)
            {
                let currentLink = this.allImages[i];
                if(currentLink.url === this.product.mainImage.url)
                {
                    currentLink.indx = 0;
                }
            }
            this.product.mainImage = this.selectedImage;
            this.product.mainImage.indx = 0;
            this.allImages.sort((a, b) => (a.indx > b.indx) ? 1 : -1)
            for(let i=1; i<this.allImages.length; i++)
            {
                this.allImages[i].indx = i;
            }
            this.selectedImage = {
            url: "",
            indx: 0,
            keyword: ""
            };
            this.snackbarMsg = "Ustawiono nowe zdjęcie główne produktu.";
            this.showSnackbar = true;    
        },
        deleteFromGallery()
        {
            let foundI = null;
            for(let i=0; i< this.allImages.length; i++)
            {
                if(this.allImages[i] === this.selectedImage)
                {
                    if(i!=0)
                    {
                        foundI = i;
                    }
                    if(this.allImages[i].url === this.product.mainImage.url)
                    {
                        this.product.mainImage = {
                        url: "",
                        indx: 0,
                        keyword: ""
                        };
                    }
                    this.selectedToDelete.push(this.allImages[i]);
                    this.allImages.splice(i, 1);
                    this.selectedImage = {
                    url: "",
                    indx: 0,
                    keyword: ""
                    };
                    this.snackbarMsg = "Usunięto. Aby cofnąć, wejdź ponownie w produkt.";
                    this.showSnackbar = true;   
                }
                if(foundI !== null)
                {
                    if(foundI>=i)
                    {
                        this.allImages[i].indx--;
                    }
                }
            }
            for(let i=0; i< this.allImages.length; i++)
            {
                let currentLink = this.allImages[i];
                currentLink.indx = i;
            }
            this.allImages.sort((a, b) => (a.indx > b.indx) ? 1 : -1);
        },
        deleteAllFromGallery()
        {
            for(let i=0; i< this.allImages.length; i++)
            {
                if(this.allImages.length > 0)
                {
                    if(this.allImages[i].url === this.product.mainImage.url)
                    {
                        this.product.mainImage = {
                        url: "",
                        indx: 0,
                        keyword: ""
                        };
                    }
                    this.selectedToDelete.push(this.allImages[i]);
                    while(this.allImages.length)
                    {
                        this.allImages.pop()
                    }
                    this.selectedImage = {
                    url: "",
                    indx: 0,
                    keyword: ""
                    };
                    this.snackbarMsg = "Usunięto wszystkie zdjęcia. Aby cofnąć, wejdź ponownie w produkt.";
                    this.showSnackbar = true;   
                }

            }
            for(let i=0; i< this.allImages.length; i++)
            {
                let currentLink = this.allImages[i];
                currentLink.indx = i;
            }
            this.allImages.sort((a, b) => (a.indx > b.indx) ? 1 : -1);
        },
        getFileExtension(filename)
        {
        let ext = /^.+\.([^.]+)$/.exec(filename);
        return ext == null ? "" : ext[1];
        },
        // Go to products list
        goBackToList()
        {
            this.$emit('backToProductList');
        },
        // Dynamic name of product
        createFileName(length) {
            var result = [];
            var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            var charactersLength = characters.length;
            for ( var i = 0; i < length; i++ ) {
            result.push(characters.charAt(Math.floor(Math.random() * 
                charactersLength)));
                }
            return result.join('');
        },
        // Upload Image
        async uploadSectionLink(imgRef,indx)
        {
            let storageRef = storage.ref();
            let name = this.createFileName(24);
            let ext = this.getFileExtension(imgRef.name)
            let fileRef = storageRef.child(`${name}.${ext}`);
            await fileRef.put(imgRef);
            let link =  await fileRef.getDownloadURL();
            this.product.descSections[indx].url = link;
        },
        async uploadImage(imgRef,productBracket)
        {
            this.loadingInfo = `Upload pliku: ${imgRef.name}`;
            const compressionOptions = {
                initialQuality: 1
            }
            const compressedFile = await imageCompression(imgRef, compressionOptions);
            let storageRef = storage.ref();
            let name = this.createFileName(24);
            let ext = this.getFileExtension(imgRef.name)
            let fileRef = storageRef.child(`${name}mainImage.${ext}`);
            await fileRef.put(compressedFile); // here imgRef if dont want to compress
            if(productBracket === "mainImage")
            {
                // GET REFERENCE TO PRODUCT
                let link =  await fileRef.getDownloadURL();
                let imageScheme = {
                    url: link,
                    indx: 0,
                    keyword: ""
                }
                this.product.mainImage = imageScheme;
            }
            if(productBracket === "imageGallery")
            {
                let link = await fileRef.getDownloadURL()
                // GET REFERENCE TO PRODUCT
                let indx = this.product.imageGallery.length+1;
                let imageScheme = {
                    url: link,
                    indx: indx,
                    keyword: ""
                }
                this.product.imageGallery.push(imageScheme);
            }
            if(productBracket === "edit")
            {
                let add = 0;
                if(this.product.mainImage.url==="" && this.allImages.length === 0)
                {
                    add = 1;
                }
                if(this.allImages.length>0)
                {
                    add = 1;
                }
                let link = await fileRef.getDownloadURL();
                let imageScheme = {
                    url: link,
                    indx: this.allImages.length+add,
                    keyword: ""
                }
                // GET REFERENCE TO PRODUCT
                this.allImages.push(imageScheme);
                this.showEditModeUpload = true;
                for(let i=0; i<this.allImages.length; i++)
                {
                    this.allImages[i].indx = i;
                }
                this.allImages.sort((a, b) => (a.indx > b.indx) ? 1 : -1);
            }
        },
        // Validate on edit
        validateItemFormulaEditMode()
        {
            if(this.product.isUnique === true)
            {
                this.product.quantity = 1;
            }
            if(this.product.name.length>0)
            {
                this.errors.nameError = false;
                if(this.product.quantity>0)
                {
                    this.errors.qtyError = false;
                    if(this.product.basePrice>0)
                    {
                        this.errors.priceError = false;
                        if(this.product.mainImage.url.length>0)
                        {
                            this.errors.imageError = false;
                            this.product.specs.weight.value = parseFloat(this.product.specs.weight.value);
                            if(this.product.specs.weight.value>0)
                            {
                                this.errors.weightError = false;
                                this.askForEdit = true;
                            }
                            else
                            {
                                this.currentStep = 'third';
                                this.errors.weightError = true;
                                this.showSnackbar = true;
                                this.snackbarMsg = "Waga powinna zostać uzupełniona.";
                            }
                            
                        }
                        else
                        {
                            // no main image
                            this.currentStep = 'first';
                            this.errors.imageError = true;
                            this.showSnackbar = true;
                            this.snackbarMsg = "Nie wybrano obrazka produktu.";
                        }
                    }
                    else
                    {
                        this.currentStep = "first";
                        this.errors.priceError = true;
                        this.showSnackbar = true;
                        this.snackbarMsg = "Błędna cena produktu.";
                        // too low price
                    }
                }
                else
                {
                    this.currentStep = "first";
                    this.errors.qtyError = true;
                    this.showSnackbar = true;
                    this.snackbarMsg = "Błędnie uzupełniona ilość produktu.";
                    
                    
                    // error product quantity
                }
            }
            else 
            {
                this.currentStep = "first";
                this.errors.nameError = true;
                this.showSnackbar = true;
                this.snackbarMsg = "Błędnie uzupełniona nazwa produktu.";
                // error product name
            }
        },
        // Validate formula of product
        validateItemFormula()
        {
            if(this.product.isUnique === true)
            {
                this.product.quantity = 1;
            }
            if(this.product.name.length>0)
            {
                this.errors.nameError = false;
                if(this.product.quantity>0)
                {
                    this.errors.qtyError = false;
                    if(this.product.basePrice>0)
                    {
                        this.errors.priceError = false;
                        if(this.$refs.main.$refs.inputFile.files.length>0)
                        {
                            this.errors.imageError = false;
                            this.product.specs.weight.value = parseFloat(this.product.specs.weight.value);
                            if(this.product.specs.weight.value>0)
                            {
                                this.errors.weightError = false;
                                this.askForAdd = true;
                            }
                            else
                            {
                                this.currentStep = 'third';
                                this.errors.weightError = true;
                                this.showSnackbar = true;
                                this.snackbarMsg = "Waga powinna zostać uzupełniona.";
                            }
                            
                        }
                        else
                        {
                            // no main image
                            this.currentStep = 'first';
                            this.errors.imageError = true;
                            this.showSnackbar = true;
                            this.snackbarMsg = "Nie wybrano obrazka produktu.";
                        }
                    }
                    else
                    {
                        this.currentStep = "first";
                        this.errors.priceError = true;
                        this.showSnackbar = true;
                        this.snackbarMsg = "Błędna cena produktu.";
                        // too low price
                    }
                }
                else
                {
                    this.currentStep = "first";
                    this.errors.qtyError = true;
                    this.showSnackbar = true;
                    this.snackbarMsg = "Błędnie uzupełniona ilość produktu.";
                    
                    
                    // error product quantity
                }
            }
            else 
            {
                this.currentStep = "first";
                this.errors.nameError = true;
                this.showSnackbar = true;
                this.snackbarMsg = "Błędnie uzupełniona nazwa produktu.";
                // error product name
            }
        },
        preparePhotosOnEdit()
        {
                for(let i=0; i<this.allImages.length; i++)
                {
                    if(this.allImages[i].url === this.product.mainImage.url)
                    {
                        this.allImages.splice(i,1);
                    }
                    this.product.imageGallery = this.allImages;
                };
            /*
            for(let y=0; y< this.selectedToDelete.length; y++)
            {
                this.deleteImage(this.selectedToDelete[y].url);
            }
            */
        },
        onConfirmEditItem()
        {
            Object.keys(this.product).map(k => this.product[k] = typeof this.product[k] == 'string' ? this.product[k].trim() : this.product[k]);

            this.preparePhotosOnEdit();
            this.loading = true;

            this.loadingInfo = `Zlecanie aktualizacji produktu.`;
            this.product.edited = firebase.firestore.FieldValue.serverTimestamp();
            this.editedPrice = parseFloat(this.beforeEditPrice)
            this.product.basePrice = parseFloat(this.product.basePrice);
            this.product.priceBeforePromotion = parseFloat(this.product.priceBeforePromotion);
            this.product.addonsIds = [];
            for(let i=0; i<this.preparedAddons.length;i++)
            {
                let currentAddon = this.preparedAddons[i];
                let idScheme = {
                    name: currentAddon.name,
                    id: currentAddon.id
                    }
                this.product.addonsIds.push(idScheme);
            }
            //this.preparedAddons = [];
            const backupId = db.collection('Backups').doc('Products').collection('editedProducts').doc()
            const logId = db.collection('Logs').doc()
                db.collection('Logs').doc(logId.id).set({
                message: `Edycja produktu ${this.product.name}, przez ${this.userInfo.name} ${this.userInfo.surname}, wprowadzone zmiany: 
                NAZWA PRODUKTU: ${this.beforeEditProduct.name} ⭢ ${this.product.name},  
                CENA: ${this.editedPrice}PLN ⭢ ${this.product.basePrice}PLN`,
                user: {
                mail: this.userInfo.mail,
                name: this.userInfo.name,
                surname: this.userInfo.surname,
                },
                backupEditedItemeId: backupId.id,
                oldProduct: this.beforeEditProduct,
                createdAt: new Date(),
                uniqueID: logId.id,
                baselinkerID: this.product.baselinkerProductID,
                sellasistID: this.product.sellasistProductID
            })

          db.collection('Backups').doc('Products').collection('editedProducts').doc(backupId.id).set({
            oldProductName: this.beforeEditProduct.name,
            productName: this.product.name,
            oldProduct: this.beforeEditProduct,
            product: this.product,
            createdAt: new Date()
          })

            db.collection("Products").doc(this.product.uniqueID).set(this.product).then(()=>
            {
                this.loadingInfo = `Kończenie aktualizacji produktu.`;
                this.loading = false;
                this.snackbarMsg = "Produkt zapisany pomyślnie do bazy danych."
                this.showSnackbar = true;
                //this.$emit('backToProductList');
            }).catch((error)=>
            {
                alert("Coś poszło nie tak. Więcej w konsoli.")
                console.log(error);
            })
        },
        // After confirming product add
        onConfirmAddItem()
        {
            
            this.loading = true;
            this.loadingInfo = `Zlecanie wysyłki plików na serwer Kompre.`;
            // UPLOAD IMAGES TO STORAGE
            const mainImageRefList = this.$refs.main.$refs.inputFile.files;
            const galleryImageRefList = this.$refs.gallery.$refs.inputFile.files;
            this.product.sellasistPreparedImages = [];
            
            if(mainImageRefList.length>0)
            {
                this.loading = true;
                //this.getBase64(mainImageRefList[0]);
                this.uploadImage(mainImageRefList[0],"mainImage");
                
            }
            if(galleryImageRefList.length>0)
            {
                for(let i = 0; i < galleryImageRefList.length; i++)
                {
                    this.loading = true;
                    //this.getBase64(galleryImageRefList[i]);
                    this.uploadImage(galleryImageRefList[i],"imageGallery");
                }
            }
            let checker = setInterval(()=>
            {
                this.loadingInfo = `Oczekiwanie na potwierdzenie wgrania plików.`;
                if(this.product.imageGallery.length === galleryImageRefList.length && this.product.mainImage.url.length>0)
                {
                    clearInterval(checker);
                    // ADD PRODUCT TO DATABASE
                    this.product.edited = firebase.firestore.FieldValue.serverTimestamp();
                    this.product.created = firebase.firestore.FieldValue.serverTimestamp();
                    this.loadingInfo = `Tworzenie produktu oraz przypisywanie do kategorii.`;
                    this.product.basePrice = parseFloat(this.product.basePrice);
                    this.product.priceBeforePromotion = parseFloat(this.product.priceBeforePromotion);
                    // prepareAddons v2
                    for(let i=0; i<this.preparedAddons.length;i++)
                    {
                        let currentAddon = this.preparedAddons[i];
                        let idScheme = {
                            name: currentAddon.name,
                            id: currentAddon.id
                        }
                        this.product.addonsIds.push(idScheme);
                    }

                    db.collection("Products").add(this.product)
                    .then((snapshot) => {
                        let uid = snapshot.id;
                        let link = "https://kompreshop.web.app/produkt/"+uid;
                        this.product.uniqueID = uid;
                        this.product.linkToItem = link;
                        // Adding UID to document;
                        db.collection('Products').doc(uid).update({
                        uniqueID: uid,
                        linkToItem: link
                        })

                        .then(()=>
                        {
                            // Adding product to category
                            db.collection('Categories').doc(this.product.category).update({
                            productsID: firebase.firestore.FieldValue.arrayUnion(uid)
                            }).then(()=>
                            {
                                if(this.product.category !== "Usługi"){
                                    this.loadingInfo = `Skracanie linków do obrazków poprzez Bit.ly`;
                                    let allPromises = [];
                                    allPromises.push(this.shortenUrl({link: this.product.mainImage.url}));
                                    for(let i=0; i<this.product.imageGallery.length; i++)
                                    {
                                        let currentLink = this.product.imageGallery[i].url;
                                        allPromises.push(this.shortenUrl({link: currentLink}));
                                    }
                                    Promise.all(allPromises)
                                    .then((results)=>
                                    {
                                        for(let y=0; y<results.length;y++)
                                        {
                                            let currentResult = results[y];
                                            // this.product.baselinkerPreparedImages.push('url:'+currentResult.data.link);
                                            this.product.sellasistPreparedImages.push(currentResult.data.link);
                                        }
                                        // if(results.length>16)
                                        // {
                                        //     alert("Baselinker przyjmuje tylko do 16 zdjęć. Część twoich obrazków może się nie wgrać prawidłowo.")
                                        // }
                                        this.loadingInfo = `Dodawanie produktu do systemu Sellasist.`;
                                        axios.post('https://europe-central2-kompreshop.cloudfunctions.net/webApi/addProductToSellasist', this.product).then((res)=>
                                        {
                                            this.loadingInfo = `Przypisywanie ID produktu Sellasist do produktu sklepowego.`;
                                            this.loading = false;
                                            if(res.data.product_id != null)
                                            {
                                                db.collection('Products').doc(this.product.uniqueID).update({
                                                    "sellasistProductID": res.data.product_id,
                                                    // "sku": res.data.product_id
                                                }).then(() => {
                                                    const logId = db.collection('Logs').doc()
                                                    db.collection('Logs').doc(logId.id).set({
                                                        message: `Dodanie nowego produktu ${this.product.name}, przez ${this.userInfo.name} ${this.userInfo.surname}`,
                                                        user: {
                                                            mail: this.userInfo.mail,
                                                            name: this.userInfo.name,
                                                            surname: this.userInfo.surname,
                                                        },
                                                        product: this.product,
                                                        createdAt: new Date(),
                                                        uniqueID: logId.id,
                                                        sellasistID: res.data.product_id,
                                                    })          
                                                    this.loadingInfo = `Kończenie procesu dodawania produktu.`;
                                                    this.$emit('showSuccess');
                                                }).catch((err) => {
                                                    console.log(err);
                                                    alert("Nie przypisano ID Sellasist do produktu sklepowego. Wiecej w konsoli.");
                                                })
                                            }
                                            else
                                            {
                                                alert("Dodawanie do systemu Sellasist nie zakończyło się sukcesem. Sellasist zwrócił inny status. Więcej w konsoli.");
                                                console.log(res.data);
                                            }
                                        }).catch((err)=>
                                        {
                                            console.log(err);
                                            alert("Wystąpił błąd podczas dodawania produktu do systemu Sellasist. Więcej w konsoli.")
                                        })

                                        // this.loadingInfo = `Dodawanie produktu do Baselinkera.`;
                                        // axios.post('https://europe-central2-kompreshop.cloudfunctions.net/webApi/AddProductToBaselinker',this.product).then((res)=>
                                        // {
                                        //     this.loadingInfo = `Przypisywanie ID produktu Baselinkera do produktu sklepowego.`;
                                        //     this.loading = false;
                                        //     if(res.data.status === "SUCCESS")
                                        //     {
                                        //         console.log(res.data.product_id);
                                        //         db.collection('Products').doc(this.product.uniqueID).update({
                                        //         baselinkerProductID: res.data.product_id,
                                        //         }).then(()=>
                                        //         {
                                        //     const logId = db.collection('Logs').doc()
                                        //         db.collection('Logs').doc(logId.id).set({
                                        //             message: `Dodanie nowego produktu ${this.product.name}, przez ${this.userInfo.name} ${this.userInfo.surname}`,
                                        //             user: {
                                        //                 mail: this.userInfo.mail,
                                        //                 name: this.userInfo.name,
                                        //                 surname: this.userInfo.surname,
                                        //             },
                                        //             product: this.product,
                                        //             createdAt: new Date(),
                                        //             uniqueID: logId.id,
                                        //             baselinkerID: res.data.product_id,
                                        //         })          
                                        //             this.loadingInfo = `Kończenie procesu dodawania produktu.`;
                                        //             this.$emit('showSuccess');

                                        //         }).catch((err)=>
                                        //         {
                                        //             console.log(err);
                                        //             alert("Nie przypisano ID baselinkera do produktu sklepowego. Wiecej w konsoli.");
                                        //         })
                                        //     }
                                        //     else
                                        //     {
                                        //         alert("Dodawanie do baselinkera nie zakończyło się sukcesem. Baselinker zwrócił inny status. Więcej w konsoli.");
                                        //         console.log(res.data);
                                        //     }
                                        // }).catch((err)=>
                                        // {
                                        //     console.log(err);
                                        //     alert("Wystąpił błąd podczas dodawania produktu do Baselinkera. Więcej w konsoli.")
                                        // })

                                    }).catch((error)=>
                                    {
                                        console.log(error);
                                        alert("Nie mozna skorzystać z Bit.ly Prawdopodonie wykorzystałeś juz 1000 linków w tym miesiącu. Dokup pakiet. Wiecej w konsoli.")
                                    })
                                }else{
                                    this.loadingInfo = `Kończenie procesu dodawania produktu.`;
                                    this.$emit('showSuccess');
                                }

                            }).catch((error)=>
                            {
                                this.loadingInfo = `Wystąpił błąd w dopisywaniu do kategorii: ${error}`;
                            })
                        }).catch((error)=>
                        {
                            this.loadingInfo = `Wystąpił błąd w dopisywaniu do kategorii: ${error}`;
                        })
                    })
                    .catch((error) => {
                        this.loadingInfo = `Wystąpił błąd w tworzeniu produktu: ${error}`;
                    });
                }
                else 
                {
                    this.loadingInfo = `Oczekiwanie na potwierdzenie wgrania plików.`;
                }
            },2000)
        },
        // After negate product add
        onCancelAddItem()
        {  
            this.loading = false;
        },
        addExistingAddon(addon)
        {
                let newAddon = {
                    name: addon.name,
                    options: addon.options,
                    id: addon.id,
                    percantagePrice: addon.percantagePrice
                }
                
                this.preparedAddons.push(newAddon)
                this.showDialogAddons = false;
                this.snackbarMsg = "Dodano opcję rozbudowy do produktu";
                this.showSnackbar = true;
        },
        addAddon()
        {
                // Nowy dodatek
                let newAddon = {
                    name: this.newAddonName,
                    options: [],
                    id: ""
                };
                if(this.newAddonName.length>0)
                {
                    this.product.addons.push(newAddon);
                    this.newAddonName = "";
                    this.selectedIndexOfAddon = 0;
                }
        },
        VariantToAddon()
        {
                // Nowy wariant dodatku
                this.product.addons[this.selectedIndexOfAddon].options.push({
                    name: this.variantName,
                    price: parseFloat(this.variantPrice)
                });
                this.variantName = '';
                this.variantPrice = 0;
        },
        deleteAddon(index)
        {
                // Usuwanie dodatku 
                this.preparedAddons.splice(index,1);
                for(let i=0;i<this.product.addonsIds.length;i++)
                {
                    if(this.preparedAddons.id===this.product.addonsIds[i].id)
                    {
                        this.product.addonsIds.splice(i,1);
                    }
                }
        },
        deleteVariant(variantIndex,addonIndex)
        {
                // Usuwanie wariantu dodatku
                this.product.addons[addonIndex].options.splice(variantIndex,1)
        }
    }
}
</script>
<style lang="scss">

.chosenAsMain
{
    border: 1px solid #e03024;
    border-radius: 5px;
}

.selectedFromGallery
{
    border: 1px solid #a3a3a3;
    opacity: 0.7;
    border-radius: 5px;
}

.imgError
{
    text-align: left;
    padding-top: 1em;
    padding-bottom: 1em;
    color: red;
}

textarea[name=test] {
  resize: none !important;
  max-height: 250px !important;
}


.closeButton
{
    margin-left: 1em;
    width: 15px;
    height: auto;
}

.closeButton:hover
{
    cursor: pointer;
    opacity: 0.5;
}

.addNewItem
{
    width: 100%;

    &__childProducts
    {
        margin-top: 1rem;
        margin-bottom: 1rem;    
        display: flex;
        justify-content: center;
        flex-direction: column;
        width: 50%;

        &__name
        {
            margin-bottom: 0.5rem;
        }

        &__container
        {
            margin-top: 0.3rem;
            margin-bottom: 0.3rem;
            display: flex;
            align-items: center;
            width: 100%;

            &__childName
            {
                margin-right: 1rem;
            }

            &__image
            {
                width: 50px;
                height: 50px;
                margin-right: 1rem;

                img
                {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }

    &__productInfo
    {
        display:flex;
        flex-direction: column;
    }

    &__imageGallery
    {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        width: 100%;
        margin-bottom: 1em;

        &__uploadNew
        {
            margin-top: 1em;
            margin-bottom: 1em;
            width: 100%;
            display: flex;
            flex-direction: column;

            &__uploader
            {
                display: flex;
                align-items: center;
            }
        }

        &__galleryRows
        {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            width: 100%;
            height: 250px;
            margin-top: 0.5em;
            margin-bottom: 0.5em;

            &__image
            {
                margin: 0.5em;
                width: 250px;
                height: 250px;
                overflow: hidden;
                object-fit: fill;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                transition: all 0.4s ease-in;


                img
                {
                    object-fit: contain;
                    height: 100%;
                    width: auto;
                    padding: 5px;
                }
            }

            &__image:hover
            {
               
               cursor: pointer;
               opacity: 0.5;
            }
        }
    }

    &__itemDescription
    {
        width: 100%;
        display: flex;
        flex-direction: column;

        &__section
        {
            margin-top: 1rem;
            margin-bottom: 1rem;

            &__form
            {
                margin-top: 0.3rem;
            }
        }
    }


    &__nextStep
    {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-bottom: 2em;
        margin-top: 2em;
    }

    &__title
    {
        margin-bottom: 1em;
        display: flex;
        align-items: center;

        img 
        {
            width: 4em;
            margin-right: 1em;
            height: auto;
        }
    }

    &__addons
    {
        margin-top: 2em;
        
        &__addon
        {
            margin-top: 0.5rem;
            &__main
            {
                display: flex;
                align-items: center;
            }

            &__columns
            {
                display: flex;
                &__column
                {
                    margin-right: 2em;
                    margin-bottom: 1em;

                    &__variant
                    {
                        margin-top: 1em;
                        display: flex;
                        align-items: center;


                    }

                    &__saveVariant
                    {
                        margin-top: 1rem;
                    }
                }
            }
        }
    }

    &__columns
    {
        width: 90%;
        display: flex;
        justify-content: space-between;

        &__column
        {
            display: flex;
            width: 45%;
        }
    }

        &__options
        {
            padding-top: 2em;
            width: 100%;

            &__option
            {
                &__doubleText
                {
                    display: flex;
                    flex-direction: column;
                }
            }

            &__newAddon
            {
                display: flex;
            }
        }
}

.addonTab
{
    margin-top: 1rem;
    overflow-y: scroll;
    height:400px;

    &__addNew
    {
        margin-top: 1rem;
        width: 50%;
    }

    &__addNewOption
    {
        display: flex;
        width: 100%;

        &__optionCreator
        {
            margin-right: 1rem;
        }

        &__scheme
        {
            margin-top: 1rem;
            display: flex;
            flex-direction: column;
            &__schemeOptions
            {
                margin-top: 0.5rem;
                margin-bottom: 0.5rem;
                display: flex;
                align-items: center;
                justify-content: center;

                &__save
                {
                    margin-top: 0.5rem;
                    display: flex;
                    justify-content: flex-end;
                }
            }
        }
    }



    &__header
    {
        margin-bottom: 0.5rem;
    }

    &__item
    {
        margin-top: 0.5rem;

        &__manage
        {
            margin-top: 1rem;
            width: 100%;
            margin-bottom: 1rem;

            &__inputs
            {
                display: flex;
                width: 100%;
                &__input
                {
                    margin-right: 1rem;
                    width: 30%;
                }
            }
        }
    &__name
    {
        display: flex;
        align-items: center;
    }
        &__options
        {
            margin-left: 1rem;
        }
    }
}

.md-menu-content
{
    max-width: 50% !important;
}

.spaceLeft
{
    margin-left: 1rem;
}

.disabledOpt
{
    opacity: 0.3;
}

.disabledOpt:hover
{
    opacity: 0.3;
}

.editAddonInput
{
    margin-right: 0.64rem;
    margin-left: 0.64rem;
    width: 4rem;
    border-radius: 5px;
    border: 1px solid #d4d4d4;
    padding: 0.262rem;

    &--long
    {
        padding: 0.262rem;
        margin-right: 0.64rem;
        margin-left: 0.64rem;
        border-radius: 5px;
        border: 1px solid #d4d4d4;
        width: 7rem;
    }
}


</style>
